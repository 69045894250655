import React, {
	FC,
	RefObject,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react'
import {
	ConcernFieldNames,
	ConcernedInfosSectionProps,
	classes
} from './__index.utils'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { formatStrapiText } from '@utils/methods'
import { RadioList, RequestInputs, TNRequestNames } from '@utils/request'
import { Direction, REQUEST_CONFIGS_KEYS } from '@services/constants'
import RadioInput from '@components/requestForms/inputs/radio.input'
import requestForm from '@components/requestForms/request.form'
import MapForm from '../map/MapForm'
import { joinClasses } from '@utils/styles'
import TextInput from '@components/requestForms/inputs/text.inputs'
import { useAppSelector } from '@services/store'
// import MaskedTextInput from './inputs/textWithValidation.inputs'
import * as formUtils from '@pages/auth/__index.utils'
import { PHONE_NUMBER_REGEX } from '@pages/auth/profile/__index.utils'
import MaskedTextInput from '@components/requestForms/inputs/textWithValidation.inputs'
import { AddressInfo } from '../map/mapForm.interfaces'
import { AddressCoordinates } from '@services/models'

const ConcernedInfosSection: FC<ConcernedInfosSectionProps> = ({
	form,
	errors,
	inputs,
	isConcernedInfosRequired,
	subStep,
	requiredFields,
	fieldsAlternativeLabel,
	onFixError,
	onSetInputs,
	onGetCadastralAndMatriculeNumber,
	isSameAddressAsPersonalInfor
}) => {
	const { authUser } = useContext<AppContextProps>(AppStateContext)

	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	const answers = useAppSelector((state) => state.request.configs)

	const [concernedLastNameInput, setConcernedLastNameInput] =
		useState<RequestInputs>({
			name: ConcernFieldNames.concernedLastName,
			label: fieldsAlternativeLabel?.lastName
				? fieldsAlternativeLabel?.lastName
				: pageAssets?.label_lastName,
			labelKey: 'label_lastName',
			value: answers.concernedLastName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [concernedFirstNameInput, setConcernedFirstNameInput] =
		useState<RequestInputs>({
			name: ConcernFieldNames.concernedFirstName,
			label: pageAssets?.label_firstName,
			labelKey: 'label_firstName',
			value: answers.concernedFirstName || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [concernedHasLavalAdresseInput, setConcernedHasLavalAdresseInput] =
		useState<RequestInputs>({
			name: ConcernFieldNames.concernedHasLavalAdresse,
			label: pageAssets?.label_concernedHasLavalAdresse,
			labelKey: 'label_concernedHasLavalAdresse',
			value: answers.concernedHasLavalAdresse?.toString() || 'true',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const radioListHasLavalAdresse: RadioList[] = [
		{
			label: pageAssets?.request_form_select_adresse_at_laval,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_adresse_outside_laval,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const [concernedAdresseInput, setConcernedAdresseInput] =
		useState<RequestInputs>({
			name: ConcernFieldNames.concernedAdresse,
			label: pageAssets?.label_address,
			labelKey: 'label_address',
			value: answers.concernedAdresse || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [concernedAppartementInput, setConcernedAppartementInput] =
		useState<RequestInputs>({
			name: ConcernFieldNames.concernedAppartement,
			label: `${formatStrapiText(pageAssets?.label_apartment_office)}`,
			labelKey: 'label_apartment_office',
			value: answers.concernedAppartement || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [concernedEmailInput, setConcernedEmailInput] = useState<RequestInputs>(
		{
			name: ConcernFieldNames.concernedEmail,
			label: pageAssets?.label_email,
			labelKey: 'label_email',
			value: answers.concernedEmail || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		}
	)

	const [concernedPhoneNumberInput, setConcernedPhoneNumberInput] =
		useState<RequestInputs>({
			name: ConcernFieldNames.concernedPhoneNumber,
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.concernedPhoneNumber || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [concernedCityInput, setConcernedCityInput] = useState<RequestInputs>({
		name: ConcernFieldNames.concernedCity,
		label: pageAssets?.label_city,
		labelKey: 'label_city',
		value: answers.concernedCity || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: subStep
	})

	const [concernedProvinceInput, setConcernedProvinceInput] =
		useState<RequestInputs>({
			name: ConcernFieldNames.concernedProvince,
			label: pageAssets?.label_state,
			labelKey: 'label_state',
			value: answers.concernedProvince || 'Quebec',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [concernedPostalCodeInput, setConcernedPostalCodeInput] =
		useState<RequestInputs>({
			name: ConcernFieldNames.concernedPostalCode,
			label: pageAssets?.label_postalCode,
			labelKey: 'label_postalCode',
			value: answers.concernedPostalCode || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	const [concernedCountryInput, setConcernedCountryInput] =
		useState<RequestInputs>({
			name: ConcernFieldNames.concernedCountry,
			label: pageAssets?.label_country,
			labelKey: 'label_country',
			value: answers.concernedCountry || 'Canada',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: subStep
		})

	//------------------------------------------------------------
	const onSetConcernedLastNameInput = (value: string) => {
		setConcernedLastNameInput({ ...concernedLastNameInput, value })

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, concernedLastNameInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(concernedLastNameInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, concernedLastNameInput))
	}, [concernedLastNameInput])

	//------------------------------------------------------------
	const onSetConcernedFirstNameInput = (value: string) => {
		setConcernedFirstNameInput({ ...concernedFirstNameInput, value })

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, concernedFirstNameInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(concernedFirstNameInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, concernedFirstNameInput))
	}, [concernedFirstNameInput])

	//------------------------------------------------------------
	const onSetConcernedHasLavalAdresseInput = (value: string) => {
		setConcernedHasLavalAdresseInput({
			...concernedHasLavalAdresseInput,
			value
		})

		if (value === 'false') {
			setConcernedOutsideLavalAdresseInputsRequired(true)
		} else {
			setConcernedOutsideLavalAdresseInputsRequired(false)
		}

		onFixError &&
			errors &&
			onFixError(
				errors.filter(
					(error) =>
						![
							concernedAdresseInput.name,
							concernedCityInput.name,
							concernedProvinceInput.name,
							concernedPostalCodeInput.name,
							concernedCountryInput.name
						].includes(error)
				)
			)
	}

	useEffect(() => {
		isFieldRequired(concernedHasLavalAdresseInput.name) &&
			onSetInputs &&
			onSetInputs(
				UpdateRequestInputsArray(inputs!, concernedHasLavalAdresseInput)
			)
	}, [concernedHasLavalAdresseInput])

	//------------------------------------------------------------
	const onSetConcernedAdresseInput = (value: string) => {
		setConcernedAdresseInput({ ...concernedAdresseInput, value })
		// onGetCadastralAndMatriculeNumber && onGetCadastralAndMatriculeNumber(value)

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, concernedAdresseInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(concernedAdresseInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, concernedAdresseInput))
	}, [concernedAdresseInput])

	//------------------------------------------------------------
	const onSetConcernedAppartementInput = (value: string) => {
		setConcernedAppartementInput({ ...concernedAppartementInput, value })

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, concernedAppartementInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(concernedAppartementInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, concernedAppartementInput))
	}, [concernedAppartementInput])

	//------------------------------------------------------------
	const onSetConcernedEmailInput = (value: string) => {
		setConcernedEmailInput({ ...concernedEmailInput, value })

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, concernedEmailInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(concernedEmailInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, concernedEmailInput))
	}, [concernedEmailInput])

	//------------------------------------------------------------
	const onSetConcernedPhoneNumberInput = (value: string) => {
		setConcernedPhoneNumberInput({ ...concernedPhoneNumberInput, value })

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, concernedPhoneNumberInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(concernedPhoneNumberInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, concernedPhoneNumberInput))
	}, [concernedPhoneNumberInput])

	//------------------------------------------------------------
	const onSetConcernedCityInput = (value: string) => {
		setConcernedCityInput({ ...concernedCityInput, value })

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, concernedCityInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(concernedCityInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, concernedCityInput))
	}, [concernedCityInput])

	//------------------------------------------------------------
	const onSetConcernedProvinceInput = (value: string) => {
		setConcernedProvinceInput({ ...concernedProvinceInput, value })

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, concernedProvinceInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(concernedProvinceInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, concernedProvinceInput))
	}, [concernedProvinceInput])

	//------------------------------------------------------------
	const onSetConcernedPostalCodeInput = (value: string) => {
		setConcernedPostalCodeInput({
			...concernedPostalCodeInput,
			value: value?.toUpperCase()
		})

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, concernedPostalCodeInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(concernedPostalCodeInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, concernedPostalCodeInput))
	}, [concernedPostalCodeInput])

	//------------------------------------------------------------
	const onSetConcernedCountryInput = (value: string) => {
		setConcernedCountryInput({ ...concernedCountryInput, value })

		onFixError &&
			onFixError(
				UpdateRequestInputsErrorsArray(errors!, concernedCountryInput.name)
			)
	}

	useEffect(() => {
		isFieldRequired(concernedCountryInput.name) &&
			onSetInputs &&
			onSetInputs(UpdateRequestInputsArray(inputs!, concernedCountryInput))
	}, [concernedCountryInput])

	//------------------------------------------------------------

	useEffect(() => {
		if (isConcernedInfosRequired == true) {
			setConcernedInputsRequired(true)
			if (concernedHasLavalAdresseInput.value == 'false') {
				setConcernedOutsideLavalAdresseInputsRequired(true)
			}
		} else {
			setConcernedInputsRequired(false)
			setConcernedOutsideLavalAdresseInputsRequired(false)
		}

		onFixError &&
			errors &&
			onFixError(
				errors.filter(
					(error) =>
						![
							concernedLastNameInput.name,
							concernedFirstNameInput.name,
							concernedEmailInput.name,
							concernedPhoneNumberInput.name,
							concernedHasLavalAdresseInput.name,
							concernedAdresseInput.name,
							concernedAppartementInput.name,
							concernedCityInput.name,
							concernedProvinceInput.name,
							concernedPostalCodeInput.name,
							concernedCountryInput.name
						].includes(error)
				)
			)
	}, [isConcernedInfosRequired])

	useEffect(() => {
		if (isConcernedInfosRequired == true) {
			setConcernedInputsRequired(true)
			if (concernedHasLavalAdresseInput.value == 'false') {
				setConcernedOutsideLavalAdresseInputsRequired(true)
			}
		} else {
			setConcernedInputsRequired(false)
			setConcernedOutsideLavalAdresseInputsRequired(false)
		}
	}, [])

	useEffect(() => {
		// si l'adresse est la meme, plus aucun besoin de setter le concerned adresse (API prend automatically adresse de l'utilisateur)
		//
		// if (isSameAddressAsPersonalInfor) {
		// 	setConcernedAdresseInput({
		// 		...concernedAdresseInput,
		// 		value: authUser?.profile?.address.address ?? ''
		// 	})
		// 	setConcernedAppartementInput({
		// 		...concernedAppartementInput,
		// 		value: authUser?.profile?.address.apartment ?? ''
		// 	})
		// 	setConcernedCityInput({
		// 		...concernedCityInput,
		// 		value: authUser?.profile?.address.city ?? ''
		// 	})
		// 	setConcernedProvinceInput({
		// 		...concernedProvinceInput,
		// 		value: authUser?.profile?.address.state ?? ''
		// 	})
		// 	setConcernedPostalCodeInput({
		// 		...concernedPostalCodeInput,
		// 		value: authUser?.profile?.address.postalCode ?? ''
		// 	})
		// 	setConcernedCountryInput({
		// 		...concernedCountryInput,
		// 		value: authUser?.profile?.address.country ?? ''
		// 	})
		// }
	}, [isSameAddressAsPersonalInfor])

	const setConcernedInputsRequired = (value: boolean) => {
		if (isFieldRequired(concernedFirstNameInput.name)) {
			setConcernedFirstNameInput({
				...concernedFirstNameInput,
				required: value
			})
		}
		if (isFieldRequired(concernedEmailInput.name)) {
			setConcernedEmailInput({
				...concernedEmailInput,
				required: value
			})
		}
		if (isFieldRequired(concernedPhoneNumberInput.name)) {
			setConcernedPhoneNumberInput({
				...concernedPhoneNumberInput,
				required: value
			})
		}
		if (isFieldRequired(concernedLastNameInput.name)) {
			setConcernedLastNameInput({
				...concernedLastNameInput,
				required: value
			})
		}
		if (isFieldRequired(concernedAdresseInput.name)) {
			setConcernedAdresseInput({
				...concernedAdresseInput,
				required: value
			})
		}
	}

	const setConcernedOutsideLavalAdresseInputsRequired = (value: boolean) => {
		if (isFieldRequired(concernedCityInput.name)) {
			setConcernedCityInput({
				...concernedCityInput,
				required: value
			})
		}
		if (isFieldRequired(concernedPostalCodeInput.name)) {
			setConcernedPostalCodeInput({
				...concernedPostalCodeInput,
				required: value
			})
		}
		if (isFieldRequired(concernedProvinceInput.name)) {
			setConcernedProvinceInput({
				...concernedProvinceInput,
				required: value
			})
		}
		if (isFieldRequired(concernedCountryInput.name)) {
			setConcernedCountryInput({
				...concernedCountryInput,
				required: value
			})
		}
	}

	const onAddressChange = (address: AddressInfo) => {
		if (address) {
			setConcernedCityInput({
				...concernedCityInput,
				value: address.city ? address.city : 'Laval'
			})
			setConcernedPostalCodeInput({
				...concernedPostalCodeInput,
				value: address.postalCode
			})
			onGetCadastralAndMatriculeNumber &&
				onGetCadastralAndMatriculeNumber(
					address.coordinates as AddressCoordinates
				)
		}
	}

	const renderMapForm = () => {
		return (
			<MapForm
				addressRequired={concernedAdresseInput.required}
				classNames={{
					address: joinClasses([classes.formInput, classes.map])
				}}
				hasError={errors?.includes(concernedAdresseInput.name)}
				addressLabel={pageAssets?.label_find_an_address}
				showButtonUserLocation={false}
				onAddressInputChange={onSetConcernedAdresseInput}
				onAddressChange={onAddressChange}
				isConcernedAdresseSection={true}
			/>
		)
	}

	const isFieldRequired = (fieldName) => {
		if (requiredFields) {
			return requiredFields.includes(fieldName)
		}

		return true
	}

	return (
		<>
			<div className={classes.container}>
				<div className={classes.fieldsContainer}>
					{isFieldRequired(concernedFirstNameInput.name) && (
						<TextInput
							id={concernedFirstNameInput.name}
							label={concernedFirstNameInput.label}
							value={concernedFirstNameInput.value}
							onChange={onSetConcernedFirstNameInput}
							hasError={errors?.includes(concernedFirstNameInput.name)}
							required={concernedFirstNameInput.required}
							ref={concernedFirstNameInput.ref as RefObject<HTMLInputElement>}
							maxCharacters={100}
						/>
					)}

					{isFieldRequired(concernedLastNameInput.name) && (
						<TextInput
							id={concernedLastNameInput.name}
							label={concernedLastNameInput.label}
							value={concernedLastNameInput.value}
							onChange={onSetConcernedLastNameInput}
							hasError={errors?.includes(concernedLastNameInput.name)}
							required={concernedLastNameInput.required}
							ref={concernedLastNameInput.ref as RefObject<HTMLInputElement>}
							maxCharacters={100}
						/>
					)}
				</div>

				{isFieldRequired(concernedHasLavalAdresseInput.name) && (
					<div style={{ margin: '-18px 0 10px' }}>
						<RadioInput
							name={concernedHasLavalAdresseInput.name}
							label={''}
							value={concernedHasLavalAdresseInput.value}
							list={radioListHasLavalAdresse}
							direction={Direction.horizontal}
							hasError={errors!.includes(concernedHasLavalAdresseInput.name)}
							onChange={onSetConcernedHasLavalAdresseInput}
							ref={
								concernedHasLavalAdresseInput.ref as RefObject<HTMLInputElement>
							}
						/>
					</div>
				)}

				{concernedHasLavalAdresseInput.value == 'true' ? (
					<>
						{isFieldRequired(concernedAdresseInput.name) && renderMapForm()}

						{isFieldRequired(concernedAppartementInput.name) && (
							<div className={classes.fieldsContainer}>
								<TextInput
									id={concernedAppartementInput.name}
									label={concernedAppartementInput.label}
									value={concernedAppartementInput.value}
									onChange={onSetConcernedAppartementInput}
									hasError={errors?.includes(concernedAppartementInput.name)}
									required={concernedAppartementInput.required}
									ref={
										concernedAppartementInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={50}
								/>
							</div>
						)}
					</>
				) : (
					<>
						<br />
						<div className={classes.fieldsContainer}>
							{isFieldRequired(concernedAdresseInput.name) && (
								<TextInput
									id={concernedAdresseInput.name}
									label={concernedAdresseInput.label}
									value={concernedAdresseInput.value}
									onChange={onSetConcernedAdresseInput}
									hasError={errors?.includes(concernedAdresseInput.name)}
									required={concernedAdresseInput.required}
									ref={concernedAdresseInput.ref as RefObject<HTMLInputElement>}
									maxCharacters={100}
								/>
							)}
							{isFieldRequired(concernedAppartementInput.name) && (
								<TextInput
									id={concernedAppartementInput.name}
									label={concernedAppartementInput.label}
									value={concernedAppartementInput.value}
									onChange={onSetConcernedAppartementInput}
									hasError={errors?.includes(concernedAppartementInput.name)}
									required={concernedAppartementInput.required}
									ref={
										concernedAppartementInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={50}
								/>
							)}
							{isFieldRequired(concernedCityInput.name) && (
								<TextInput
									id={concernedCityInput.name}
									label={concernedCityInput.label}
									value={concernedCityInput.value}
									onChange={onSetConcernedCityInput}
									hasError={errors?.includes(concernedCityInput.name)}
									required={concernedCityInput.required}
									ref={concernedCityInput.ref as RefObject<HTMLInputElement>}
									maxCharacters={100}
								/>
							)}
							{isFieldRequired(concernedProvinceInput.name) && (
								<TextInput
									id={concernedProvinceInput.name}
									label={concernedProvinceInput.label}
									value={concernedProvinceInput.value}
									onChange={onSetConcernedProvinceInput}
									hasError={errors?.includes(concernedProvinceInput.name)}
									required={concernedProvinceInput.required}
									ref={
										concernedProvinceInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							)}
							{isFieldRequired(concernedPostalCodeInput.name) && (
								<MaskedTextInput
									id={concernedPostalCodeInput.name}
									label={concernedPostalCodeInput.label}
									value={concernedPostalCodeInput.value}
									onChange={onSetConcernedPostalCodeInput}
									hasError={errors?.includes(concernedPostalCodeInput.name)}
									min={7}
									max={7}
									mask={formUtils.MASKS.postalCode}
									placeholder="A1A 1A1"
									errors={errors ? errors : []}
									setError={onFixError ? onFixError : () => {}}
									schema={PHONE_NUMBER_REGEX}
									required={concernedPostalCodeInput.required}
									ref={
										concernedPostalCodeInput.ref as RefObject<HTMLInputElement>
									}
								/>
							)}
							{isFieldRequired(concernedCountryInput.name) && (
								<TextInput
									id={concernedCountryInput.name}
									label={concernedCountryInput.label}
									value={concernedCountryInput.value}
									onChange={onSetConcernedCountryInput}
									hasError={errors?.includes(concernedCountryInput.name)}
									required={concernedCountryInput.required}
									ref={concernedCountryInput.ref as RefObject<HTMLInputElement>}
									maxCharacters={100}
								/>
							)}
						</div>
					</>
				)}

				<div className={classes.fieldsContainer}>
					{isFieldRequired(concernedEmailInput.name) && (
						<TextInput
							id={concernedEmailInput.name}
							label={concernedEmailInput.label}
							value={concernedEmailInput.value}
							onChange={onSetConcernedEmailInput}
							hasError={errors?.includes(concernedEmailInput.name)}
							type="email"
							onSetError={onFixError}
							schema={formUtils.MASKS.email}
							schemaErrorText={pageAssets?.label_emailErrorText}
							required={concernedEmailInput.required}
							ref={concernedEmailInput.ref as RefObject<HTMLInputElement>}
							maxCharacters={100}
						/>
					)}

					{isFieldRequired(concernedPhoneNumberInput.name) && (
						<MaskedTextInput
							id={concernedPhoneNumberInput.name}
							label={concernedPhoneNumberInput.label}
							value={concernedPhoneNumberInput.value}
							onChange={onSetConcernedPhoneNumberInput}
							hasError={errors?.includes(concernedPhoneNumberInput.name)}
							type="tel"
							min={10}
							max={14}
							mask={formUtils.MASKS.phoneNumber}
							placeholder={formUtils.MASKS.phoneNumber}
							errors={errors ? errors : []}
							setError={onFixError ? onFixError : () => {}}
							schema={PHONE_NUMBER_REGEX}
							required={concernedPhoneNumberInput.required}
							ref={concernedPhoneNumberInput.ref as RefObject<HTMLInputElement>}
						/>
					)}
				</div>
			</div>
		</>
	)
}

export default ConcernedInfosSection
