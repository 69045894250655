import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	PesticideCertificates,
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	SelectList,
	VehicleInformation
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	Direction,
	requestCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { CustomDate } from '@services/models'
import { formatStrapiText, getFilesFromStore } from '@utils/methods'
import * as formUtils from '@pages/auth/__index.utils'
import { PHONE_NUMBER_REGEX } from '@pages/auth/profile/__index.utils'
import MaskedTextInput from '@components/requestForms/inputs/textWithValidation.inputs'
import Text from '@components/ui/text'
import Error from '@components/requestForms/inputs/error.input'
import ConcernedInfosSection from '@components/ui/concernedInfosSection'
import { closeIcon } from '@images/icons'
import { Icon } from '@components/ui/Icon'
import RequiredIcon from '@components/ui/requiredIcon'
import RequestFiles from '@components/ui/requestFiles'
import DateFilterInput from './inputs/datefilter.input'

const AnnualRegistrationCertificateForContractorsForm: FC<RequestFormPDFProps> =
	({
		inputs,
		errors,
		subSteps,
		inputFiles,
		isSummaryStep,
		steps,
		toPrint,
		filesInputs,
		formPosition,
		onCustomError,
		onSetInputFiles,
		onSetSubSteps,
		onFixError,
		onSetInputs,
		setCurrentStep
	}) => {
		const {
			authUser,
			pageAssets,
			UpdateRequestInputsArray,
			UpdateRequestInputFilesArray,
			UpdateRequestInputsErrorsArray
		} = requestForm()

		type Classes = {
			button: string
			outlinedButton: string
			buttonContainer: string
			fileHelperSection: string
			fileContainer: string
			inputContainer: string
			checkbox2: string
			checkbox2Label: string
			checkbox2Container: string
			checkboxWrapper: string
			detailscontainer: string
			adresseBlock: string
			h5: string
			rowSection: string
			rowNumber: string
			rowNumberContainer: string
			ligneContainer: string
			rowRemoveContainer: string
			closeIcon: string
			withoutLabel: string
			tableSummary: string
			pesticideRowSection: string
			pesticideRowHolderName: string
			vehiculeRowSection: string
			customDateInput: string
		}

		const classes: Classes = makeClasses({
			button: {
				display: 'inline-block',
				padding: '10px 20px',
				fontSize: '13px',
				fontWeight: '500',
				color: Colors.white,
				lineHeight: '1em',
				borderRadius: '23px',
				transition: 'all 0.35s ease',
				MsTransition: 'all 0.35s ease',
				background: Colors.secondary,
				border: 'none',
				cursor: 'pointer',
				'&:hover': {
					background: Colors.darkBlue2,
					borderColor: Colors.darkBlue2,
					color: Colors.white
				}
			},
			outlinedButton: {
				background: 'none',
				border: `1px solid ${Colors.secondary} !important`,
				color: Colors.secondary
			},

			buttonContainer: {
				display: 'flex',
				width: 'fit-content',
				columnGap: '4px'
			},
			fileHelperSection: {
				background: Colors.darkWhite,
				padding: '30px',
				marginBottom: '15px'
			},
			fileContainer: {
				'.blue-bullets-list': {
					ul: {
						padding: '0px 15px 0px 20px'
					}
				}
			},
			inputContainer: {
				marginBottom: '20px'
			},

			checkbox2: {
				width: 'fit-content',
				position: 'absolute',
				verticalAlign: 'baseline',
				clip: 'rect(1px, 1px, 1px, 1px)'
			},
			checkbox2Label: {
				fontSize: '16px',
				padding: '0',
				color: Colors.lightBlack,
				position: 'relative',
				paddingLeft: '27px',
				fontWeight: 'normal',
				cursor: 'pointer',
				lineHeight: '17px',
				':before': {
					content: "''",
					position: 'absolute',
					top: '0',
					left: '0',
					width: '16px',
					height: '16px',
					border: `1px solid ${Colors.lightGrey3}`,
					background: Colors.white,
					outline: 'none !important'
				},
				':after': {
					content: "''",
					position: 'absolute',
					top: '4px',
					left: '4px',
					width: '10px',
					height: '10px',
					background: Colors.secondary,
					outline: 'none',
					opacity: '0',
					transition: 'all .15s ease-out'
				}
			},
			checkbox2Container: {
				'& input[value=true] ~ label:after': {
					opacity: '.99'
				}
			},
			checkboxWrapper: {
				display: 'block',
				margin: '0 0 5px 0',
				background: Colors.ligthGrey2,
				border: `1px solid ${Colors.greyAlto}`,
				padding: '14px 12px'
			},

			adresseBlock: {
				border: `1px solid ${Colors.greyAlto}`,
				padding: '20px 25px',
				marginBottom: '10px'
			},
			h5: {
				fontSize: '20px',
				fontWeight: '600',
				margin: '15px 0 10px 0'
			},
			rowSection: {
				display: 'grid',
				alignItems: 'end',
				// gridTemplateColumns: '3%21%19%19%15%15%3%',
				columnGap: '1%',
				[Breakpoints.maxWidth('sm')]: {
					gridTemplateColumns: '47.5%47.5% !important',
					marginBottom: '15px'
				},
				'& > div': {
					marginBottom: '8px'
				}
			},
			pesticideRowSection: {
				gridTemplateColumns: '3%21%19%19%15%15%3%',
				[Breakpoints.maxWidth('sm')]: {
					'& > div:nth-child(3)': {
						gridColumn: '1/3'
					}
				}
			},
			vehiculeRowSection: {
				gridTemplateColumns: '3%17%17%12%15%14%13%3%'
			},
			rowNumberContainer: {
				display: 'flex',
				'& span': {
					marginBottom: '9px'
				},

				[Breakpoints.maxWidth('sm')]: {
					'&:after': {
						background: '#ccc',
						height: '1px',
						content: '""',
						position: 'absolute',
						right: '35px',
						width: 'calc(100% - 92px)',
						marginTop: '12px'
					}
				}
			},
			rowRemoveContainer: {
				display: 'flex',
				'& > span': {
					marginBottom: '9px'
				}
			},

			rowNumber: {
				fontWeight: '500',
				color: Colors.white,
				borderRadius: '50%',
				fontSize: '14px',
				width: '26px',
				height: '26px',
				lineHeight: '26px',
				background: Colors.lightBlue,
				textAlign: 'center',
				display: 'block'
			},
			ligneContainer: {
				display: 'none',
				[Breakpoints.maxWidth('sm')]: {
					display: 'flex'
				}
			},
			closeIcon: {
				display: 'inline-flex',
				height: '30px',
				marginLeft: '-3px',
				cursor: 'pointer',
				alignSelf: 'flex-start',
				marginBottom: '7px',
				img: {
					height: '100%'
				},

				'& span': {
					display: 'none'
				},

				[Breakpoints.maxWidth('sm')]: {
					marginTop: '5px',
					height: '27px',
					'& span': {
						display: 'inline',
						color: '#0054a6',
						textDecoration: 'underline',
						lineHeight: '27px'
					}
				}
			},

			withoutLabel: {
				'& > label': {
					display: 'none',
					[Breakpoints.maxWidth('sm')]: {
						display: 'block'
					}
				}
			},
			tableSummary: {
				borderCollapse: 'collapse',
				width: '100%',
				'& td, & th': {
					fontSize: '16px',
					border: '1px solid #ccc',
					padding: '7px 15px',
					lineHeight: '28px'
				},
				'& tr:nth-child(even)': {
					backgroundColor: Colors.lightGrey4
				}
			},
			customDateInput: {
				'& input': {
					padding: '7px 12px'
				}
			}
		})

		const answers = useAppSelector((state) => state.request.configs)
		const location = useAppSelector((state) => state.request.location)

		const [showCompanyAdresseBlock, setShowCompanyAdresseBlock] =
			useState<boolean>(!answers?.isSameInformations)

		//----------------------------------------------------------------
		//--------------------- DETAILS 1 INPUTS -------------------------
		//----------------------------------------------------------------

		// ************* Informations relatives à l'entreprise ***********

		// Nom de l'entreprise
		const [companyNameInput, setCompanyNameInput] = useState<RequestInputs>({
			name: 'companyName',
			label:
				pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label,
			labelKey:
				'request_form_annualRegistrationCertificateForContractors_companyName_label',
			value: answers.companyName || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

		// Numéro d'entreprise du Québec
		const [quebecBusinessNumberInput, setQuebecBusinessNumberInput] =
			useState<RequestInputs>({
				name: 'quebecBusinessNumber',
				label:
					pageAssets?.request_form_annualRegistrationCertificateForContractors_quebecBusinessNumber_label,
				labelKey:
					'request_form_annualRegistrationCertificateForContractors_quebecBusinessNumber_label',
				description:
					pageAssets?.request_form_annualRegistrationCertificateForContractors_quebecBusinessNumber_description,
				value: answers.quebecBusinessNumber || '',
				required: true,
				ref: useRef<HTMLSelectElement>(null),
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
			})

		// Nom et prénom du représentant
		const [representativeNameInput, setRepresentativeNameInput] =
			useState<RequestInputs>({
				name: 'representativeName',
				label:
					pageAssets?.request_form_annualRegistrationCertificateForContractors_representativeName_label,
				labelKey:
					'request_form_annualRegistrationCertificateForContractors_representativeName_label',
				value: answers.representativeName || '',
				required: true,
				ref: useRef<HTMLSelectElement>(null),
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
			})

		// Titre du représentant
		const [representativeTitleInput, setRepresentativeTitleInput] =
			useState<RequestInputs>({
				name: 'representativeTitle',
				label:
					pageAssets?.request_form_annualRegistrationCertificateForContractors_representativeTitle_label,
				labelKey:
					'request_form_annualRegistrationCertificateForContractors_representativeTitle_label',
				value: answers.representativeTitle || '',
				required: true,
				ref: useRef<HTMLSelectElement>(null),
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
			})

		// ************* Coordonnées de l'entreprise ***********

		// Adresse
		const [companyAdresseInput, setCompanyAdresseInput] =
			useState<RequestInputs>({
				name: 'companyAdresse',
				label: pageAssets?.label_address,
				labelKey: 'label_address',
				value: answers.companyAdresse || '',
				required: true,
				ref: useRef<HTMLSelectElement>(null),
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
			})

		// Appartement / Bureau
		const [companyAppartementInput, setCompanyAppartementInput] =
			useState<RequestInputs>({
				name: 'companyAppartement',
				label: `${formatStrapiText(pageAssets?.label_apartment_office)}`,
				labelKey: 'label_apartment_office',
				value: answers.companyAppartement || '',
				required: false,
				ref: useRef<HTMLSelectElement>(null),
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
			})

		const [companyCityInput, setCompanyCityInput] = useState<RequestInputs>({
			name: 'companyCity',
			label: pageAssets?.label_city,
			labelKey: 'label_city',
			value: answers.companyCity || '',
			required: true,
			ref: useRef<HTMLInputElement>(null)
		})

		const [companyProvinceInput, setCompanyProvinceInput] =
			useState<RequestInputs>({
				name: 'companyProvince',
				label: pageAssets?.label_state,
				labelKey: 'label_state',
				value: answers.companyProvince || 'Quebec',
				required: true,
				ref: useRef<HTMLSelectElement>(null),
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
			})

		const [companyPostalCodeInput, setCompanyPostalCodeInput] =
			useState<RequestInputs>({
				name: 'companyPostalCode',
				label: pageAssets?.label_postalCode,
				labelKey: 'label_postalCode',
				value: answers.companyPostalCode || '',
				required: true,
				ref: useRef<HTMLSelectElement>(null),
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
			})

		const [companyCountryInput, setCompanyCountryInput] =
			useState<RequestInputs>({
				name: 'companyCountry',
				label: pageAssets?.label_country,
				labelKey: 'label_country',
				value: answers.companyCountry || 'Canada',
				required: true,
				ref: useRef<HTMLSelectElement>(null),
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
			})

		const [companyEmailInput, setCompanyEmailInput] = useState<RequestInputs>({
			name: 'companyEmail',
			label: pageAssets?.label_email,
			labelKey: 'label_email',
			value: answers.companyEmail || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

		const [companyPhoneNumberInput, setCompanyPhoneNumberInput] =
			useState<RequestInputs>({
				name: 'companyPhoneNumber',
				label: pageAssets?.label_telephone,
				labelKey: 'label_telephone',
				value: answers.companyPhoneNumber || '',
				required: true,
				ref: useRef<HTMLSelectElement>(null),
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
			})

		//----------------------------------------------------------------
		//-------------------- SubStep DETAILS_2 INPUTS ------------------
		//----------------------------------------------------------------

		// Mêmes informations que celle inscrite dans les informations de l'entreprise
		const [isSameInformationsInput, setIsSameInformationsInput] =
			useState<RequestInputs>({
				name: 'isSameInformations',
				label: pageAssets?.request_form_isSameInformations_label,
				labelKey: 'request_form_isSameInformations_label',
				value: answers.isSameInformations?.toString() || 'false',
				required: true,
				ref: useRef<HTMLSelectElement>(null),
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
			})

		// Numéro du permis / License number
		const [permitLicenseNumberInput, setPermitLicenseNumberInput] =
			useState<RequestInputs>({
				name: 'permitLicenseNumber',
				label:
					pageAssets?.request_form_annualRegistrationCertificateForContractors_permitLicense_label,
				labelKey:
					'request_form_annualRegistrationCertificateForContractors_permitLicense_label',
				value: answers.permitLicenseNumber?.toString() || '',
				required: true,
				ref: useRef<HTMLSelectElement>(null),
				description:
					pageAssets?.request_form_annualRegistrationCertificateForContractors_permitLicense_description,
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
			})

		const radioListYesNo: RadioList[] = [
			{
				label: pageAssets.request_form_select_yes,
				value: 'true',
				key: 'request_form_select_yes'
			},
			{
				label: pageAssets.request_form_select_no,
				value: 'false',
				key: 'request_form_select_no'
			}
		]

		// Date de fin de validité du permis
		const [permitExpiryDateInput, setPermitExpiryDateInput] =
			useState<RequestInputs>({
				name: 'permitExpiryDate',
				label:
					pageAssets?.request_form_annualRegistrationCertificateForContractors_permitExpiryDate_label,
				labelKey:
					'request_form_annualRegistrationCertificateForContractors_permitExpiryDate_label',
				value: answers.permitExpiryDate || '',
				required: true,
				ref: useRef<HTMLSelectElement>(null),
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
			})

		////////////////////////////////////////////////////////////////

		const [pesticideCertificatesList, setPesticideCertificatesList] = useState<
			PesticideCertificates[]
		>(
			answers?.pesticideCertificatesList
				? answers.pesticideCertificatesList
				: [
						{
							holder_name: '',
							certificat_number: '',
							category: '',
							issue_date: '',
							expiration_date: ''
						}
				  ]
		)

		// a fake RequestInputs to put pesticideCertificatesList data in
		// value will be encoded as JSON
		const [pesticideCertificatesListInput, setPesticideCertificatesListInput] =
			useState<RequestInputs>({
				name: 'pesticideCertificatesList',
				label:
					pageAssets?.request_form_annualRegistrationCertificateForContractors_information_title,
				labelKey:
					'request_form_annualRegistrationCertificateForContractors_information_title',
				value: JSON.stringify(pesticideCertificatesList),
				required: true,
				ref: useRef<HTMLSelectElement>(null),
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
			})

		//----------------------------------------------------------------
		//-------------------- DESCRIPTION INPUTS ------------------------
		//----------------------------------------------------------------

		const [vehicleInformationList, setVehicleInformationList] = useState<
			VehicleInformation[]
		>(
			answers?.vehicleInformationList
				? answers.vehicleInformationList
				: [
						{
							brand: '',
							model: '',
							year: '',
							color: '',
							registration_number: '',
							business_identified: false
						}
				  ]
		)

		// a fake RequestInputs to put vehicleInformationList data in
		// value will be encoded as JSON
		const [vehicleInformationListInput, setVehicleInformationListInput] =
			useState<RequestInputs>({
				name: 'vehicleInformationList',
				label:
					pageAssets?.request_form_annualRegistrationCertificateForContractors_information_title,
				labelKey:
					'request_form_annualRegistrationCertificateForContractors_information_title',
				value: JSON.stringify(vehicleInformationList),
				required: true,
				ref: useRef<HTMLSelectElement>(null),
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
			})

		//----------------------------------------------------------------
		//----------------------- DOCUMENTS INPUTS -----------------------
		//----------------------------------------------------------------

		// Certificat d'assurance
		const [firstQuestion, setFirstQuestion] = useState<RequestInputFiles>({
			name: 'firstInput',
			label:
				pageAssets?.request_form_annualRegistrationCertificateForContractors_firstInput_label,
			labelKey:
				'request_form_annualRegistrationCertificateForContractors_firstInput_label',
			description:
				pageAssets?.request_form_annualRegistrationCertificateForContractors_firstInput_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'firstInput')?.files || []
			),
			required: true,
			numberMaxFiles: 10,
			category: requestCategories.proofOfInsurance
		})

		// Permis MELCCFP
		const [secondQuestion, setSecondQuestion] = useState<RequestInputFiles>({
			name: 'secondInput',
			label:
				pageAssets?.request_form_annualRegistrationCertificateForContractors_secondInput_label,
			labelKey:
				'request_form_annualRegistrationCertificateForContractors_secondInput_label',
			description:
				pageAssets?.request_form_annualRegistrationCertificateForContractors_secondInput_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'secondInput')?.files || []
			),
			required: true,
			numberMaxFiles: 10,
			category: requestCategories.permitMELCCFP
		})

		// Certificats de compétence
		const [thirdQuestion, setThirdQuestion] = useState<RequestInputFiles>({
			name: 'thirdInput',
			label:
				pageAssets?.request_form_annualRegistrationCertificateForContractors_thirdInput_label,
			labelKey:
				'request_form_annualRegistrationCertificateForContractors_thirdInput_label',
			description:
				pageAssets?.request_form_annualRegistrationCertificateForContractors_thirdInput_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'thirdInput')?.files || []
			),
			required: true,
			numberMaxFiles: 10,
			category: requestCategories.competenceCertificate
		})

		// Preuve d'immatriculation
		const [fourthQuestion, setFourthQuestion] = useState<RequestInputFiles>({
			name: 'fourthInput',
			label:
				pageAssets?.request_form_annualRegistrationCertificateForContractors_fourthInput_label,
			labelKey:
				'request_form_annualRegistrationCertificateForContractors_fourthInput_label',
			description:
				pageAssets?.request_form_annualRegistrationCertificateForContractors_fourthInput_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'fourthInput')?.files || []
			),
			required: true,
			numberMaxFiles: 10,
			category: requestCategories.registration
		})

		// Copie des affiches réglementaires Preuve d'immatriculation
		const [fifthQuestion, setFifthQuestion] = useState<RequestInputFiles>({
			name: 'fifthInput',
			label:
				pageAssets?.request_form_annualRegistrationCertificateForContractors_fifthInput_label,
			labelKey:
				'request_form_annualRegistrationCertificateForContractors_fifthInput_label',
			description:
				pageAssets?.request_form_annualRegistrationCertificateForContractors_fifthInput_description,
			files: new Set<File>(
				filesInputs?.find((el) => el.name == 'fifthInput')?.files || []
			),
			required: false,
			numberMaxFiles: 10,
			category: requestCategories.other
		})

		// ------------------- DETAILS INPUTS SETTERS --------------------

		//------------------------------------------------------------
		const onSetCompanyNameInput = (value: string) => {
			setCompanyNameInput({ ...companyNameInput, value })

			onFixError(UpdateRequestInputsErrorsArray(errors!, companyNameInput.name))
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, companyNameInput))
		}, [companyNameInput])

		//------------------------------------------------------------
		const onSetQuebecBusinessNumberInput = (value: string) => {
			setQuebecBusinessNumberInput({ ...quebecBusinessNumberInput, value })

			onFixError(
				UpdateRequestInputsErrorsArray(errors!, quebecBusinessNumberInput.name)
			)
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, quebecBusinessNumberInput))
		}, [quebecBusinessNumberInput])

		//------------------------------------------------------------
		const onSetRepresentativeNameInput = (value: string) => {
			setRepresentativeNameInput({ ...representativeNameInput, value })

			onFixError(
				UpdateRequestInputsErrorsArray(errors!, representativeNameInput.name)
			)
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, representativeNameInput))
		}, [representativeNameInput])

		//------------------------------------------------------------
		const onSetRepresentativeTitleInput = (value: string) => {
			setRepresentativeTitleInput({ ...representativeTitleInput, value })

			onFixError(
				UpdateRequestInputsErrorsArray(errors!, representativeTitleInput.name)
			)
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, representativeTitleInput))
		}, [representativeTitleInput])

		//------------------------------------------------------------
		const onSetCompanyAdresseInput = (value: string) => {
			setCompanyAdresseInput({ ...companyAdresseInput, value })

			onFixError(
				UpdateRequestInputsErrorsArray(errors!, companyAdresseInput.name)
			)
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, companyAdresseInput))
		}, [companyAdresseInput])

		//------------------------------------------------------------
		const onSetCompanyAppartementInput = (value: string) => {
			setCompanyAppartementInput({ ...companyAppartementInput, value })

			onFixError(
				UpdateRequestInputsErrorsArray(errors!, companyAppartementInput.name)
			)
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, companyAppartementInput))
		}, [companyAppartementInput])

		//------------------------------------------------------------
		const onSetCompanyCityInput = (value: string) => {
			setCompanyCityInput({ ...companyCityInput, value })

			onFixError(UpdateRequestInputsErrorsArray(errors!, companyCityInput.name))
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, companyCityInput))
		}, [companyCityInput])

		//------------------------------------------------------------
		const onSetCompanyProvinceInput = (value: string) => {
			setCompanyProvinceInput({ ...companyProvinceInput, value })

			onFixError(
				UpdateRequestInputsErrorsArray(errors!, companyProvinceInput.name)
			)
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, companyProvinceInput))
		}, [companyProvinceInput])

		//------------------------------------------------------------
		const onSetCompanyPostalCodeInput = (value: string) => {
			setCompanyPostalCodeInput({ ...companyPostalCodeInput, value })

			onFixError(
				UpdateRequestInputsErrorsArray(errors!, companyPostalCodeInput.name)
			)
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, companyPostalCodeInput))
		}, [companyPostalCodeInput])

		//------------------------------------------------------------
		const onSetCompanyCountryInput = (value: string) => {
			setCompanyCountryInput({ ...companyCountryInput, value })

			onFixError(
				UpdateRequestInputsErrorsArray(errors!, companyCountryInput.name)
			)
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, companyCountryInput))
		}, [companyCountryInput])

		//------------------------------------------------------------
		const onSetCompanyEmailInput = (value: string) => {
			setCompanyEmailInput({ ...companyEmailInput, value })

			onFixError(
				UpdateRequestInputsErrorsArray(errors!, companyEmailInput.name)
			)
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, companyEmailInput))
		}, [companyEmailInput])

		//------------------------------------------------------------
		const onSetCompanyPhoneNumberInput = (value: string) => {
			setCompanyPhoneNumberInput({ ...companyPhoneNumberInput, value })

			onFixError(
				UpdateRequestInputsErrorsArray(errors!, companyPhoneNumberInput.name)
			)
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, companyPhoneNumberInput))
		}, [companyPhoneNumberInput])

		//------------------------------------------------------------

		const onSelectIsSameInformationsInput = (value) => {
			setIsSameInformationsInput({
				...isSameInformationsInput,
				value: `${value}`
			})

			setTimeout(() => {
				setShowCompanyAdresseBlock(!value)
			}, 100)

			onFixError(
				UpdateRequestInputsErrorsArray(errors, isSameInformationsInput.name)
			)
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, isSameInformationsInput))
		}, [isSameInformationsInput])

		// ----------------------------------------------------------------

		const onSelectPermitLicenseNumberInput = (value: string) => {
			setPermitLicenseNumberInput({
				...permitLicenseNumberInput,
				value: value
			})

			onFixError(
				errors.filter(
					(error) => ![permitLicenseNumberInput.name].includes(error)
				)
			)
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, permitLicenseNumberInput))
		}, [permitLicenseNumberInput])

		// ----------------------------------------------------------------

		const onSelectPermitExpiryDateInput = (value: string) => {
			setPermitExpiryDateInput({ ...permitExpiryDateInput, value })

			onFixError(
				UpdateRequestInputsErrorsArray(errors, permitExpiryDateInput.name)
			)
		}

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, permitExpiryDateInput))
		}, [permitExpiryDateInput])

		// ----------------------------------------------------------------
		// ------------------  PesticideCertificates ----------------------
		// ----------------------------------------------------------------

		////////////////////////////////////////////////////////////////

		useEffect(() => {
			onSetInputs(
				UpdateRequestInputsArray(inputs, pesticideCertificatesListInput)
			)
		}, [pesticideCertificatesListInput])

		const onSetPesticideCertificatesCurrentInput = (
			value: string,
			currentInput
		) => {
			const { row, attribute } = currentInput

			let itemList = JSON.parse(JSON.stringify(pesticideCertificatesList))

			if (value !== '' && Number(value) < 0) {
				return
			}

			itemList[row][`${attribute}`] = value

			setPesticideCertificatesList(itemList)

			setPesticideCertificatesListInput({
				...pesticideCertificatesListInput,
				value: JSON.stringify(itemList)
			})

			onFixError(updatePesticideCertificatesErrorsArray(itemList))
		}

		const addPesticideCertificatesRow = () => {
			let itemList = JSON.parse(JSON.stringify(pesticideCertificatesList))

			let newItem: PesticideCertificates = {
				holder_name: '',
				certificat_number: '',
				category: '',
				issue_date: '',
				expiration_date: ''
			}

			itemList.push(newItem)

			setPesticideCertificatesList(itemList)

			setPesticideCertificatesListInput({
				...pesticideCertificatesListInput,
				value: JSON.stringify(itemList)
			})

			onFixError(updatePesticideCertificatesErrorsArray(itemList))
		}

		const removePesticideCertificatesRow = (row: number) => {
			let itemList = [...pesticideCertificatesList]

			itemList.splice(row, 1)

			setPesticideCertificatesList(itemList)

			setPesticideCertificatesListInput({
				...pesticideCertificatesListInput,
				value: JSON.stringify(itemList)
			})

			let itemListLength = itemList.length

			onFixError(
				updatePesticideCertificatesRowErrorsArray([
					`pesticideCertificatesHolder_name${row}`,
					`pesticideCertificatesCertificat_number${row}`,
					`pesticideCertificatesCategory${row}`,
					`pesticideCertificatesIssue_date${row}`,
					`pesticideCertificatesExpiration_date${row}`,
					// suppr last row errors
					`pesticideCertificatesHolder_name${itemListLength}`,
					`pesticideCertificatesCertificat_number${itemListLength}`,
					`pesticideCertificatesCategory${itemListLength}`,
					`pesticideCertificatesIssue_date${itemListLength}`,
					`pesticideCertificatesExpiration_date${itemListLength}`
				])
			)
		}

		const updatePesticideCertificatesRowErrorsArray = (
			inputNameList: string[]
		): string[] => {
			return errors.filter((err) => !inputNameList.includes(err))
		}

		// @Todo Fct to refactor
		const updatePesticideCertificatesErrorsArray = (
			itemList: PesticideCertificates[]
		): string[] => {
			let errorArray: string[] = []

			errors?.forEach((error) => {
				if (error.includes('pesticideCertificates')) {
					itemList.forEach((pesticideCertificates, row) => {
						if (
							pesticideCertificates.holder_name == '' &&
							error == `pesticideCertificatesHolder_name${row}`
						) {
							errorArray.push(error)
						}
						if (
							pesticideCertificates.certificat_number == '' &&
							error == `pesticideCertificatesCertificat_number${row}`
						) {
							errorArray.push(error)
						}
						if (
							pesticideCertificates.category == '' &&
							error == `pesticideCertificatesCategory${row}`
						) {
							errorArray.push(error)
						}
						if (
							pesticideCertificates.issue_date == '' &&
							error == `pesticideCertificatesIssue_date${row}`
						) {
							errorArray.push(error)
						}
						if (
							pesticideCertificates.expiration_date == '' &&
							error == `pesticideCertificatesExpiration_date${row}`
						) {
							errorArray.push(error)
						}
					})
				} else {
					errorArray.push(error)
				}
			})

			return errorArray
		}

		///////////////////// RENDERING ROW FUNCTION ///////////////////////

		const renderPesticideCertificatesRow = (
			pesticideCertificates: PesticideCertificates,
			row: number
		) => {
			return (
				<>
					<div
						className={joinClasses([
							classes.rowSection,
							classes.pesticideRowSection
						])}
					>
						<div
							className={
								subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
									? classes.rowNumberContainer
									: ''
							}
						>
							<span className={classes.rowNumber}>{row + 1}</span>
						</div>

						<div className={classes.ligneContainer}> </div>

						<TextInput
							id={`pesticideCertificatesHolder_name${row}`}
							label={
								pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesCertificateHolder_label
							}
							value={pesticideCertificates.holder_name}
							onChange={(e) =>
								onSetPesticideCertificatesCurrentInput(e, {
									row,
									attribute: 'holder_name'
								})
							}
							hasError={errors.includes(
								`pesticideCertificatesHolder_name${row}`
							)}
							classWrapper={row > 0 ? classes.withoutLabel : ''}
							required
						/>

						<TextInput
							id={`pesticideCertificatesCertificat_number${row}`}
							label={
								pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesNumber_label
							}
							value={pesticideCertificates.certificat_number}
							onChange={(e) =>
								onSetPesticideCertificatesCurrentInput(e, {
									row,
									attribute: 'certificat_number'
								})
							}
							hasError={errors.includes(
								`pesticideCertificatesCertificat_number${row}`
							)}
							classWrapper={row > 0 ? classes.withoutLabel : ''}
							required
						/>

						<TextInput
							id={`pesticideCertificatesCategory${row}`}
							label={
								pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesCategory_label
							}
							value={pesticideCertificates.category}
							onChange={(e) =>
								onSetPesticideCertificatesCurrentInput(e, {
									row,
									attribute: 'category'
								})
							}
							hasError={errors.includes(`pesticideCertificatesCategory${row}`)}
							classWrapper={row > 0 ? classes.withoutLabel : ''}
							required
						/>

						<DateFilterInput
							id={`pesticideCertificatesIssue_date${row}`}
							label={
								pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesIssueDate_label
							}
							value={pesticideCertificates.issue_date}
							onChange={(e) =>
								onSetPesticideCertificatesCurrentInput(e, {
									row,
									attribute: 'issue_date'
								})
							}
							hasError={errors.includes(
								`pesticideCertificatesIssue_date${row}`
							)}
							required
							dateFormat="dd/MM/yyyy"
							classWrapper={row > 0 ? classes.withoutLabel : ''}
							maxDate={new Date(pesticideCertificates.expiration_date)}
						/>

						{/*<TextInput
							id={`pesticideCertificatesIssueDate${row}`}
							label={
								pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesIssueDate_label
							}
							value={pesticideCertificates.issueDate}
							onChange={(e) =>
								onSetPesticideCertificatesCurrentInput(e, {
									row,
									attribute: 'issueDate'
								})
							}
							hasError={errors.includes(`pesticideCertificatesIssueDate${row}`)}
							classWrapper={joinClasses([
								row > 0 ? classes.withoutLabel : '',
								classes.customDateInput
							])}
							required
							type="date"
						/>*/}

						<DateFilterInput
							id={`pesticideCertificatesExpiration_date${row}`}
							label={
								pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesExpirationDate_label
							}
							value={pesticideCertificates.expiration_date}
							onChange={(e) =>
								onSetPesticideCertificatesCurrentInput(e, {
									row,
									attribute: 'expiration_date'
								})
							}
							hasError={errors.includes(
								`pesticideCertificatesExpiration_date${row}`
							)}
							required
							dateFormat="dd/MM/yyyy"
							classWrapper={row > 0 ? classes.withoutLabel : ''}
							minDate={new Date(pesticideCertificates.issue_date + 'T12:00:00')}
						/>

						{/*<TextInput
							id={`pesticideCertificatesExpirationDate${row}`}
							label={
								pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesExpirationDate_label
							}
							value={pesticideCertificates.expirationDate}
							onChange={(e) =>
								onSetPesticideCertificatesCurrentInput(e, {
									row,
									attribute: 'expirationDate'
								})
							}
							hasError={errors.includes(
								`pesticideCertificatesExpirationDate${row}`
							)}
							classWrapper={joinClasses([
								row > 0 ? classes.withoutLabel : '',
								classes.customDateInput
							])}
							required
							type="date"
						/>*/}

						<div className={classes.rowRemoveContainer}>
							{pesticideCertificatesList.length > 1 && (
								<span
									className={classes.closeIcon}
									onClick={() => removePesticideCertificatesRow(row)}
								>
									<Icon src={closeIcon} />
									<span>{pageAssets?.delete}</span>
								</span>
							)}
						</div>
					</div>
				</>
			)
		}

		// ----------------------------------------------------------------
		// ------------------- VehicleInformation -------------------------
		// ----------------------------------------------------------------
		////////////////////////////////////////////////////////////////

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, vehicleInformationListInput))
		}, [vehicleInformationListInput])

		const onSetVehicleInformationCurrentInput = (
			value: string,
			currentInput
		) => {
			const { row, attribute } = currentInput

			let itemList = JSON.parse(JSON.stringify(vehicleInformationList))

			if (value !== '' && Number(value) < 0) {
				return
			}

			itemList[row][`${attribute}`] = value

			setVehicleInformationList(itemList)

			setVehicleInformationListInput({
				...vehicleInformationListInput,
				value: JSON.stringify(itemList)
			})

			onFixError(updateVehicleInformationErrorsArray(itemList))
		}

		const addVehicleInformationRow = () => {
			let itemList = JSON.parse(JSON.stringify(vehicleInformationList))

			let newItem: VehicleInformation = {
				brand: '',
				model: '',
				year: '',
				color: '',
				registration_number: '',
				business_identified: false
			}

			itemList.push(newItem)

			setVehicleInformationList(itemList)

			setVehicleInformationListInput({
				...vehicleInformationListInput,
				value: JSON.stringify(itemList)
			})

			onFixError(updateVehicleInformationErrorsArray(itemList))
		}

		const removeVehicleInformationRow = (row: number) => {
			let itemList = [...vehicleInformationList]

			itemList.splice(row, 1)

			setVehicleInformationList(itemList)

			setVehicleInformationListInput({
				...vehicleInformationListInput,
				value: JSON.stringify(itemList)
			})

			let itemListLength = itemList.length

			onFixError(
				updateVehicleInformationRowErrorsArray([
					`vehicleInformationBrand${row}`,
					`vehicleInformationModel${row}`,
					`vehicleInformationYear${row}`,
					`vehicleInformationColor${row}`,
					`vehicleInformationRegistration_number${row}`,
					`vehicleInformationOnCompanyName${row}`,
					// suppr last row errors
					`vehicleInformationBrand${itemListLength}`,
					`vehicleInformationModel${itemListLength}`,
					`vehicleInformationYear${itemListLength}`,
					`vehicleInformationColor${itemListLength}`,
					`vehicleInformationRegistration_number${itemListLength}`,
					`vehicleInformationOnCompanyName${itemListLength}`
				])
			)
		}

		const updateVehicleInformationRowErrorsArray = (
			inputNameList: string[]
		): string[] => {
			return errors.filter((err) => !inputNameList.includes(err))
		}

		// @Todo Fct to refactor
		const updateVehicleInformationErrorsArray = (
			itemList: VehicleInformation[]
		): string[] => {
			let errorArray: string[] = []

			errors?.forEach((error) => {
				if (error.includes('vehicleInformation')) {
					itemList.forEach((vehicleInformation, row) => {
						if (
							vehicleInformation.brand == '' &&
							error == `vehicleInformationBrand${row}`
						) {
							errorArray.push(error)
						}
						if (
							vehicleInformation.model == '' &&
							error == `vehicleInformationModel${row}`
						) {
							errorArray.push(error)
						}
						if (
							vehicleInformation.year == '' &&
							error == `vehicleInformationYear${row}`
						) {
							errorArray.push(error)
						}
						if (
							vehicleInformation.color == '' &&
							error == `vehicleInformationColor${row}`
						) {
							errorArray.push(error)
						}
						if (
							vehicleInformation.registration_number == '' &&
							error == `vehicleInformationRegistration_number${row}`
						) {
							errorArray.push(error)
						}
						// if (
						// 	vehicleInformation.onCompanyName == '' &&
						// 	error == `vehicleInformationOnCompanyName${row}`
						// ) {
						// 	errorArray.push(error)
						// }
					})
				} else {
					errorArray.push(error)
				}
			})

			return errorArray
		}

		///////////////////// RENDERING ROW FUNCTION ///////////////////////

		const renderVehicleInformationRow = (
			vehicleInformation: VehicleInformation,
			row: number
		) => {
			return (
				<>
					<div
						className={joinClasses([
							classes.rowSection,
							classes.vehiculeRowSection
						])}
					>
						<div
							className={
								subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
									? classes.rowNumberContainer
									: ''
							}
						>
							<span className={classes.rowNumber}>{row + 1}</span>
						</div>

						<div className={classes.ligneContainer}> </div>

						<TextInput
							id={`vehicleInformationBrand${row}`}
							label={
								pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationBrand_label
							}
							value={vehicleInformation.brand ?? ''}
							onChange={(e) =>
								onSetVehicleInformationCurrentInput(e, {
									row,
									attribute: 'brand'
								})
							}
							hasError={errors.includes(`vehicleInformationBrand${row}`)}
							classWrapper={row > 0 ? classes.withoutLabel : ''}
							required
						/>

						<TextInput
							id={`vehicleInformationModel${row}`}
							label={
								pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationModel_label
							}
							value={vehicleInformation.model ?? ''}
							onChange={(e) =>
								onSetVehicleInformationCurrentInput(e, {
									row,
									attribute: 'model'
								})
							}
							hasError={errors.includes(`vehicleInformationModel${row}`)}
							classWrapper={row > 0 ? classes.withoutLabel : ''}
							required
						/>

						<TextInput
							id={`vehicleInformationYear${row}`}
							label={
								pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationYear_label
							}
							value={vehicleInformation.year ?? ''}
							onChange={(e) =>
								onSetVehicleInformationCurrentInput(e, {
									row,
									attribute: 'year'
								})
							}
							hasError={errors.includes(`vehicleInformationYear${row}`)}
							classWrapper={row > 0 ? classes.withoutLabel : ''}
							required
						/>

						<TextInput
							id={`vehicleInformationColor${row}`}
							label={
								pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationColor_label
							}
							value={vehicleInformation.color ?? ''}
							onChange={(e) =>
								onSetVehicleInformationCurrentInput(e, {
									row,
									attribute: 'color'
								})
							}
							hasError={errors.includes(`vehicleInformationColor${row}`)}
							classWrapper={row > 0 ? classes.withoutLabel : ''}
							required
						/>

						<TextInput
							id={`vehicleInformationRegistration_number${row}`}
							label={
								pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationRegistration_label
							}
							value={vehicleInformation.registration_number ?? ''}
							onChange={(e) =>
								onSetVehicleInformationCurrentInput(e, {
									row,
									attribute: 'registration_number'
								})
							}
							hasError={errors.includes(
								`vehicleInformationRegistration_number${row}`
							)}
							classWrapper={row > 0 ? classes.withoutLabel : ''}
							required
						/>

						<div
							className={joinClasses([
								classes.checkbox2Container,
								row > 0 ? classes.withoutLabel : ''
							])}
						>
							<label htmlFor={`vehicleInformationBusiness_identified${row}`}>
								{
									pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationOnCompanyName_label
								}{' '}
								<RequiredIcon />
								<Error
									hasError={errors.includes(
										`vehicleInformationBusiness_identified${row}`
									)}
								/>
							</label>
							<span
								className={classes.checkboxWrapper}
								style={{
									marginBottom: 0,
									padding: '12.5px 12px',
									backgroundColor: '#fff'
								}}
							>
								<input
									onClick={(e: any) =>
										onSetVehicleInformationCurrentInput(e.target.checked, {
											row,
											attribute: 'business_identified'
										})
									}
									value={`${vehicleInformation.business_identified}`}
									className={classes.checkbox2}
									type="checkbox"
									name={`vehicleInformationBusiness_identified${row}`}
									id={`vehicleInformationBusiness_identified${row}`}
								/>
								<label
									className={classes.checkbox2Label}
									htmlFor={`vehicleInformationBusiness_identified${row}`}
								>
									{pageAssets.request_form_select_yes}
								</label>
							</span>
						</div>

						<div className={classes.rowRemoveContainer}>
							{vehicleInformationList.length > 1 && (
								<span
									className={classes.closeIcon}
									onClick={() => removeVehicleInformationRow(row)}
								>
									<Icon src={closeIcon} />
									<span>{pageAssets?.delete}</span>
								</span>
							)}
						</div>
					</div>
				</>
			)
		}

		////////////////////////////////////////////////////////////////////////////////////////////////
		////////////////////////////////////////////////////////////////////////////////////////////////
		// DOCUMENTS
		////////////////////////////////////////////////////////////////////////////////////////////////
		////////////////////////////////////////////////////////////////////////////////////////////////

		const onSelectFileFirstQuestion = (files: Set<File>) => {
			setFirstQuestion({
				...firstQuestion,
				files
			})

			onFixError(UpdateRequestInputsErrorsArray(errors, firstQuestion.name))
		}

		const onSelectFileSecondQuestion = (files: Set<File>) => {
			setSecondQuestion({
				...secondQuestion,
				files
			})

			onFixError(UpdateRequestInputsErrorsArray(errors, secondQuestion.name))
		}

		const onSelectFileThirdQuestion = (files: Set<File>) => {
			setThirdQuestion({
				...thirdQuestion,
				files
			})

			onFixError(UpdateRequestInputsErrorsArray(errors, thirdQuestion.name))
		}

		const onSelectFileFourthQuestion = (files: Set<File>) => {
			setFourthQuestion({
				...fourthQuestion,
				files
			})

			onFixError(UpdateRequestInputsErrorsArray(errors, fourthQuestion.name))
		}

		const onSelectFileFifthQuestion = (files: Set<File>) => {
			setFifthQuestion({
				...fifthQuestion,
				files
			})

			onFixError(UpdateRequestInputsErrorsArray(errors, fifthQuestion.name))
		}

		useEffect(() => {
			onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, firstQuestion))
		}, [firstQuestion])

		useEffect(() => {
			onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, secondQuestion))
		}, [secondQuestion])

		useEffect(() => {
			onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, thirdQuestion))
		}, [thirdQuestion])

		useEffect(() => {
			onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fourthQuestion))
		}, [fourthQuestion])

		useEffect(() => {
			onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fifthQuestion))
		}, [fifthQuestion])

		useEffect(() => {
			// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
			// ----------------------------
			// firstInput input
			if (!!answers.firstInput && firstQuestion.files.size == 0) {
				getFilesFromStore('firstInput', answers).then((result: Set<File>) => {
					setFirstQuestion({
						...firstQuestion,
						files: result
					})
				})
			}
			// secondInput input
			if (!!answers.secondInput && secondQuestion.files.size == 0) {
				getFilesFromStore('secondInput', answers).then((result: Set<File>) => {
					setSecondQuestion({
						...secondQuestion,
						files: result
					})
				})
			}
			// thirdInput input
			if (!!answers.thirdInput && thirdQuestion.files.size == 0) {
				getFilesFromStore('thirdInput', answers).then((result: Set<File>) => {
					setThirdQuestion({
						...thirdQuestion,
						files: result
					})
				})
			}
			// fouthInput input
			if (!!answers.fourthInput && fourthQuestion.files.size == 0) {
				getFilesFromStore('fourthInput', answers).then((result: Set<File>) => {
					setFourthQuestion({
						...fourthQuestion,
						files: result
					})
				})
			}
			// fifthInput input
			if (!!answers.fifthInput && fifthQuestion.files.size == 0) {
				getFilesFromStore('fifthInput', answers).then((result: Set<File>) => {
					setFifthQuestion({
						...fifthQuestion,
						files: result
					})
				})
			}
		}, [])

		const onSetErrorFromFileComponent = (
			hasErrorInComponent: boolean,
			inputName: string
		) => {
			if (hasErrorInComponent) {
				if (!errors.includes(inputName)) {
					onFixError((oldErrors) => [...oldErrors, inputName])
				}

				return
			}

			onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
		}

		////////////////////////////////////////////////////////////////////////////////////////////////

		useEffect(() => {
			onSetSubSteps({
				map: false,
				details_1: true,
				details_2: true,
				details_3: true,
				details_4: true,
				requiredDocument: true,
				position:
					subSteps?.position && String(subSteps?.position) !== ''
						? subSteps?.position
						: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				steps: [
					REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
					REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
					REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
					REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
					REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
				]
			})
		}, [])

		const backToForm = (step: number, subStep: string) => {
			if (setCurrentStep && onSetSubSteps && subSteps) {
				setCurrentStep(step)
				onSetSubSteps({
					...subSteps,
					position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
				})
			}
		}

		const getLabelFromList = (list, value) => {
			return list?.find((element) => element.value == value?.toString())?.label
		}

		if (isSummaryStep) {
			return (
				<section
					className={pageUtils.classes.noMargin}
					style={{ padding: '0px' }}
				>
					{subSteps?.details_1 && (
						<>
							<div>
								<a
									style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
									className={joinClasses([
										pageUtils.classes.btn,
										pageUtils.classes.button,
										pageUtils.classes.buttonOutline
									])}
									onClick={() =>
										backToForm(
											steps?.form!,
											REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
										)
									}
								>
									{pageAssets?.label_modify}
								</a>
								<h4 className={pageUtils.classes.h4}>
									{pageAssets?.request_form_company_information}
								</h4>
							</div>

							<div>
								<strong>
									{
										pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label
									}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.companyName}
								</span>
							</div>

							<div>
								<strong>
									{
										pageAssets?.request_form_annualRegistrationCertificateForContractors_quebecBusinessNumber_label
									}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.quebecBusinessNumber}
								</span>
							</div>

							<div>
								<strong>
									{
										pageAssets?.request_form_annualRegistrationCertificateForContractors_representativeName_label
									}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.representativeName}
								</span>
							</div>

							<div>
								<strong>
									{
										pageAssets?.request_form_annualRegistrationCertificateForContractors_representativeTitle_label
									}
								</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.representativeTitle}
								</span>
							</div>

							<h5 className={pageUtils.classes.h5}>
								{pageAssets?.request_form_company_details}
							</h5>

							<div>
								<strong>{pageAssets?.label_address}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.companyAdresse}
								</span>
							</div>

							{answers?.companyAppartement && (
								<div>
									<strong>{`${formatStrapiText(
										pageAssets?.label_apartment_office
									)}`}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.companyAppartement}
									</span>
								</div>
							)}

							<div>
								<strong>{pageAssets?.label_city}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.companyCity}
								</span>
							</div>

							<div>
								<strong>{pageAssets?.label_state}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.companyProvince}
								</span>
							</div>

							<div>
								<strong>{pageAssets?.label_postalCode}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.companyPostalCode}
								</span>
							</div>

							<div>
								<strong>{pageAssets?.label_country}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.companyCountry}
								</span>
							</div>

							<div>
								<strong>{pageAssets?.label_email}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.companyEmail}
								</span>
							</div>

							<div>
								<strong>{pageAssets?.label_telephone}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{answers?.companyPhoneNumber}
								</span>
							</div>
						</>
					)}

					{subSteps?.details_2 && (
						<>
							<div>
								<a
									style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
									className={joinClasses([
										pageUtils.classes.btn,
										pageUtils.classes.button,
										pageUtils.classes.buttonOutline
									])}
									onClick={() =>
										backToForm(
											steps?.form!,
											REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
										)
									}
								>
									{pageAssets?.label_modify}
								</a>
								<h4 className={pageUtils.classes.h4}>
									{
										pageAssets?.request_form_annualRegistrationCertificateForContractors_secondSubStep_title
									}
								</h4>
							</div>

							<div>
								<strong>{pageAssets[isSameInformationsInput.labelKey]}</strong>
								<br />
								<span className={pageUtils.classes.answer}>
									{getLabelFromList(
										radioListYesNo,
										answers?.isSameInformations
									)}
								</span>

								{!answers?.isSameInformations && (
									<>
										<>
											<div>
												<strong>
													{
														pageAssets?.request_form_annualRegistrationCertificateForContractors_permitHolderName
													}
												</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedLastName}
												</span>
											</div>
										</>
										<>
											<div>
												<strong>{pageAssets?.label_address}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedAdresse}
												</span>
											</div>
										</>
										<>
											<div>
												<strong>{`${formatStrapiText(
													pageAssets?.label_apartment_office
												)}`}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedAppartement}
												</span>
											</div>
										</>
										<>
											<div>
												<strong>{pageAssets?.label_email}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedEmail}
												</span>
											</div>
										</>
										<>
											<div>
												<strong>{pageAssets?.label_telephone}</strong>
												<br />
												<span className={pageUtils.classes.answer}>
													{answers?.concernedPhoneNumber}
												</span>
											</div>
										</>
									</>
								)}
								<div>
									<strong>
										{
											pageAssets?.request_form_annualRegistrationCertificateForContractors_permitLicense_label
										}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers?.permitLicenseNumber}
									</span>
								</div>
								<div>
									<strong>
										{
											pageAssets?.request_form_annualRegistrationCertificateForContractors_permitExpiryDate_label
										}
									</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{pageUtils.getLocaleDate(answers?.permitExpiryDate!)}
									</span>
								</div>
							</div>
						</>
					)}
					{subSteps?.details_3 && (
						<>
							<div>
								<a
									style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
									className={joinClasses([
										pageUtils.classes.btn,
										pageUtils.classes.button,
										pageUtils.classes.buttonOutline
									])}
									onClick={() =>
										backToForm(
											steps?.form!,
											REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
										)
									}
								>
									{pageAssets?.label_modify}
								</a>
								<h4 className={pageUtils.classes.h4}>
									{
										pageAssets?.request_form_annualRegistrationCertificateForContractors_thirdSubStep_title
									}
								</h4>
							</div>

							<div style={{ overflowX: 'auto', margin: '35px 0px' }}>
								<table className={classes.tableSummary}>
									<thead>
										<tr>
											<th></th>
											<th>
												<strong>
													{
														pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesCertificateHolder_label
													}
												</strong>
											</th>
											<th>
												<strong>
													{
														pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesNumber_label
													}
												</strong>
											</th>
											<th>
												<strong>
													{
														pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesCategory_label
													}
												</strong>
											</th>
											<th>
												<strong>
													{
														pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesIssueDate_label
													}
												</strong>
											</th>
											<th>
												<strong>
													{
														pageAssets?.request_form_annualRegistrationCertificateForContractors_pesticideCertificatesExpirationDate_label
													}
												</strong>
											</th>
										</tr>
									</thead>
									<tbody>
										{answers.pesticideCertificatesList?.map(
											(pesticideCertificates, index) => {
												return (
													<tr key={`pesticideCertificates${index}`}>
														<td>
															<span className={classes.rowNumber}>
																{index + 1}
															</span>
														</td>
														<td>{pesticideCertificates.holder_name}</td>
														<td>{pesticideCertificates.certificat_number}</td>
														<td>{pesticideCertificates.category}</td>
														<td>
															{pageUtils.getLocaleDate(
																pesticideCertificates.issue_date
															)}
														</td>
														<td>
															{pageUtils.getLocaleDate(
																pesticideCertificates.expiration_date
															)}
														</td>
													</tr>
												)
											}
										)}
									</tbody>
								</table>
							</div>
						</>
					)}

					{subSteps?.details_4 && (
						<>
							<div>
								<a
									style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
									className={joinClasses([
										pageUtils.classes.btn,
										pageUtils.classes.button,
										pageUtils.classes.buttonOutline
									])}
									onClick={() =>
										backToForm(
											steps?.form!,
											REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
										)
									}
								>
									{pageAssets?.label_modify}
								</a>
								<h4 className={pageUtils.classes.h4}>
									{
										pageAssets?.request_form_annualRegistrationCertificateForContractors_fourthSubStep_title
									}
								</h4>
							</div>

							<div style={{ overflowX: 'auto', margin: '40px 0px' }}>
								<table className={classes.tableSummary}>
									<thead>
										<tr>
											<th></th>
											<th>
												<strong>
													{
														pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationBrand_label
													}
												</strong>
											</th>
											<th>
												<strong>
													{
														pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationModel_label
													}
												</strong>
											</th>
											<th>
												<strong>
													{
														pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationYear_label
													}
												</strong>
											</th>
											<th>
												<strong>
													{
														pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationColor_label
													}
												</strong>
											</th>
											<th>
												<strong>
													{
														pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationRegistration_label
													}
												</strong>
											</th>
											<th>
												<strong>
													{
														pageAssets?.request_form_annualRegistrationCertificateForContractors_vehicleInformationOnCompanyName_label
													}
												</strong>
											</th>
										</tr>
									</thead>
									<tbody>
										{answers.vehicleInformationList?.map(
											(vehicleInformation, index) => {
												return (
													<tr key={`vehicleInformation${index}`}>
														<td>
															<span className={classes.rowNumber}>
																{index + 1}
															</span>
														</td>
														<td>{vehicleInformation.brand}</td>
														<td>{vehicleInformation.model}</td>
														<td>{vehicleInformation.year}</td>
														<td>{vehicleInformation.color}</td>
														<td>{vehicleInformation.registration_number}</td>
														<td>
															{getLabelFromList(
																radioListYesNo,
																vehicleInformation.business_identified
															)}
														</td>
													</tr>
												)
											}
										)}
									</tbody>
								</table>
							</div>
						</>
					)}

					{subSteps?.requiredDocument && (
						<>
							<div style={{ marginTop: '40px' }}>
								<a
									style={{ display: toPrint ? 'none' : 'flex', float: 'right' }}
									className={joinClasses([
										pageUtils.classes.btn,
										pageUtils.classes.button,
										pageUtils.classes.buttonOutline
									])}
									onClick={() =>
										backToForm(
											steps?.form!,
											REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
										)
									}
								>
									{pageAssets?.label_modify}
								</a>
								<h4 className={pageUtils.classes.h4}>
									{pageAssets?.request_form_required_documents}
								</h4>
							</div>

							<RequestFiles
								subtitle={firstQuestion.label}
								name={firstQuestion.name}
								required={firstQuestion.required}
								files={firstQuestion.files}
								hasError={errors.includes(firstQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileFirstQuestion}
								maxFiles={firstQuestion.numberFilesRequired}
								isSummary
							>
								<></>
							</RequestFiles>

							<RequestFiles
								subtitle={secondQuestion.label}
								name={secondQuestion.name}
								required={secondQuestion.required}
								files={secondQuestion.files}
								hasError={errors.includes(secondQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileSecondQuestion}
								maxFiles={secondQuestion.numberFilesRequired}
								isSummary
							>
								<></>
							</RequestFiles>

							<RequestFiles
								subtitle={thirdQuestion.label}
								name={thirdQuestion.name}
								required={thirdQuestion.required}
								files={thirdQuestion.files}
								hasError={errors.includes(thirdQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileThirdQuestion}
								maxFiles={thirdQuestion.numberFilesRequired}
								isSummary
							>
								<></>
							</RequestFiles>

							<RequestFiles
								subtitle={fourthQuestion.label}
								name={fourthQuestion.name}
								required={fourthQuestion.required}
								files={fourthQuestion.files}
								hasError={errors.includes(fourthQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileFourthQuestion}
								maxFiles={fourthQuestion.numberFilesRequired}
								isSummary
							>
								<></>
							</RequestFiles>

							{fifthQuestion.files.size > 0 && (
								<RequestFiles
									subtitle={fifthQuestion.label}
									name={fifthQuestion.name}
									required={fifthQuestion.required}
									files={fifthQuestion.files}
									hasError={errors.includes(fifthQuestion.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileFifthQuestion}
									maxFiles={fifthQuestion.numberFilesRequired}
									isSummary
								>
									<></>
								</RequestFiles>
							)}
						</>
					)}
				</section>
			)
		}

		return (
			<>
				{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
					<Collapse
						isOpened={
							subSteps !== undefined &&
							subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
						}
					>
						<section>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_company_information}
							</h4>
							<TextInput
								id={companyNameInput.name}
								label={companyNameInput.label}
								value={companyNameInput.value}
								onChange={onSetCompanyNameInput}
								hasError={errors?.includes(companyNameInput.name)}
								required={companyNameInput.required}
								ref={companyNameInput.ref as RefObject<HTMLInputElement>}
							/>
							<TextInput
								id={quebecBusinessNumberInput.name}
								label={quebecBusinessNumberInput.label}
								description={quebecBusinessNumberInput.description}
								value={quebecBusinessNumberInput.value}
								onChange={onSetQuebecBusinessNumberInput}
								hasError={errors?.includes(quebecBusinessNumberInput.name)}
								required={quebecBusinessNumberInput.required}
								ref={
									quebecBusinessNumberInput.ref as RefObject<HTMLInputElement>
								}
							/>
							<div className={pageUtils.classes.fieldsContainer}>
								<TextInput
									id={representativeNameInput.name}
									label={representativeNameInput.label}
									value={representativeNameInput.value}
									onChange={onSetRepresentativeNameInput}
									hasError={errors?.includes(representativeNameInput.name)}
									required={representativeNameInput.required}
									ref={
										representativeNameInput.ref as RefObject<HTMLInputElement>
									}
								/>
								<TextInput
									id={representativeTitleInput.name}
									label={representativeTitleInput.label}
									value={representativeTitleInput.value}
									onChange={onSetRepresentativeTitleInput}
									hasError={errors?.includes(representativeTitleInput.name)}
									required={representativeTitleInput.required}
									ref={
										representativeTitleInput.ref as RefObject<HTMLInputElement>
									}
								/>
							</div>

							<h5 className={pageUtils.classes.h5}>
								{pageAssets?.request_form_company_details}
							</h5>
							<br />

							<div className={pageUtils.classes.fieldsContainer}>
								<TextInput
									id={companyAdresseInput.name}
									label={companyAdresseInput.label}
									value={companyAdresseInput.value}
									onChange={onSetCompanyAdresseInput}
									hasError={errors?.includes(companyAdresseInput.name)}
									required={companyAdresseInput.required}
									ref={companyAdresseInput.ref as RefObject<HTMLInputElement>}
									maxCharacters={100}
								/>
								<TextInput
									id={companyAppartementInput.name}
									label={companyAppartementInput.label}
									value={companyAppartementInput.value}
									onChange={onSetCompanyAppartementInput}
									hasError={errors?.includes(companyAppartementInput.name)}
									required={companyAppartementInput.required}
									ref={
										companyAppartementInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={50}
								/>
								<TextInput
									id={companyCityInput.name}
									label={companyCityInput.label}
									value={companyCityInput.value}
									onChange={onSetCompanyCityInput}
									hasError={errors?.includes(companyCityInput.name)}
									required={companyCityInput.required}
									ref={companyCityInput.ref as RefObject<HTMLInputElement>}
								/>

								<TextInput
									id={companyProvinceInput.name}
									label={companyProvinceInput.label}
									value={companyProvinceInput.value}
									onChange={onSetCompanyProvinceInput}
									hasError={errors?.includes(companyProvinceInput.name)}
									required={companyProvinceInput.required}
									ref={companyProvinceInput.ref as RefObject<HTMLInputElement>}
								/>

								<TextInput
									id={companyPostalCodeInput.name}
									label={companyPostalCodeInput.label}
									value={companyPostalCodeInput.value}
									onChange={onSetCompanyPostalCodeInput}
									hasError={errors?.includes(companyPostalCodeInput.name)}
									required={companyPostalCodeInput.required}
									ref={
										companyPostalCodeInput.ref as RefObject<HTMLInputElement>
									}
								/>

								<TextInput
									id={companyCountryInput.name}
									label={companyCountryInput.label}
									value={companyCountryInput.value}
									onChange={onSetCompanyCountryInput}
									hasError={errors?.includes(companyCountryInput.name)}
									required={companyCountryInput.required}
									ref={companyCountryInput.ref as RefObject<HTMLInputElement>}
								/>
								<TextInput
									id={companyEmailInput.name}
									label={companyEmailInput.label}
									value={companyEmailInput.value}
									onChange={onSetCompanyEmailInput}
									hasError={errors?.includes(companyEmailInput.name)}
									type="email"
									onSetError={onFixError}
									schema={formUtils.MASKS.email}
									schemaErrorText={pageAssets.label_emailErrorText}
									required={companyEmailInput.required}
									ref={companyEmailInput.ref as RefObject<HTMLInputElement>}
								/>

								<MaskedTextInput
									id={companyPhoneNumberInput.name}
									label={companyPhoneNumberInput.label}
									value={companyPhoneNumberInput.value}
									onChange={onSetCompanyPhoneNumberInput}
									hasError={errors?.includes(companyPhoneNumberInput.name)}
									type="tel"
									min={10}
									max={14}
									mask={formUtils.MASKS.phoneNumber}
									placeholder={formUtils.MASKS.phoneNumber}
									errors={errors ? errors : []}
									setError={onFixError}
									schema={PHONE_NUMBER_REGEX}
									required={companyPhoneNumberInput.required}
									ref={
										companyPhoneNumberInput.ref as RefObject<HTMLInputElement>
									}
								/>
							</div>
						</section>
					</Collapse>
				)}

				{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
					<Collapse
						isOpened={
							subSteps !== undefined &&
							subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
						}
					>
						<section>
							<h4 className={pageUtils.classes.h4}>
								{
									pageAssets?.request_form_annualRegistrationCertificateForContractors_secondSubStep_title
								}
							</h4>

							<form style={{ paddingBottom: '20px' }}>
								<div className={classes.checkbox2Container}>
									<span className={classes.checkboxWrapper}>
										<input
											onClick={(e: any) =>
												onSelectIsSameInformationsInput(e.target.checked)
											}
											value={isSameInformationsInput.value}
											className={classes.checkbox2}
											type="checkbox"
											name={isSameInformationsInput.name}
											id={isSameInformationsInput.labelKey}
										/>
										<label
											className={classes.checkbox2Label}
											htmlFor={isSameInformationsInput.labelKey}
										>
											{pageAssets[isSameInformationsInput.labelKey]}
										</label>
									</span>
								</div>

								{showCompanyAdresseBlock == true ? (
									<ConcernedInfosSection
										errors={errors}
										inputs={inputs}
										onFixError={onFixError}
										onSetInputs={onSetInputs}
										isConcernedInfosRequired={
											`${isSameInformationsInput.value}` == 'false'
										}
										requiredFields={[
											'concernedLastName',
											// 'concernedFirstName',
											'concernedHasLavalAdresse',
											'concernedAdresse',
											'concernedAppartement',
											'concernedCity',
											'concernedProvince',
											'concernedPostalCode',
											'concernedCountry',
											'concernedEmail',
											'concernedPhoneNumber'
										]}
										fieldsAlternativeLabel={{
											lastName:
												pageAssets?.request_form_annualRegistrationCertificateForContractors_permitHolderName
										}}
										subStep={REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2}
									/>
								) : (
									<div className={classes.adresseBlock}>
										<div>
											<strong>
												{
													pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label
												}
											</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{answers?.companyName}
											</span>
										</div>
										<div style={{ marginBottom: '15px' }}>
											<strong>{pageAssets?.label_address}</strong>
											<br />
											{answers?.companyAdresse}
											<br />
											{answers?.companyCity}
											{answers?.companyProvince
												? ` (${answers?.companyProvince}) `
												: ' '}
											{answers?.companyPostalCode}, {answers?.companyCountry}
											<br />
											{answers?.companyAppartement
												? `${formatStrapiText(
														pageAssets?.label_apartment_office
												  )} ${answers?.companyAppartement}`
												: ''}
										</div>

										<div>
											<strong>{pageAssets?.label_email}</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{answers?.companyEmail}
											</span>
										</div>
										<div>
											<strong>{pageAssets?.label_telephone}</strong>
											<br />
											<span className={pageUtils.classes.answer}>
												{answers?.companyPhoneNumber}
											</span>
										</div>
									</div>
								)}
							</form>

							<TextInput
								id={permitLicenseNumberInput.name}
								label={permitLicenseNumberInput.label}
								value={permitLicenseNumberInput.value}
								onChange={onSelectPermitLicenseNumberInput}
								hasError={errors.includes(permitLicenseNumberInput.name)}
								required={permitLicenseNumberInput.required}
								ref={
									permitLicenseNumberInput.ref as RefObject<HTMLInputElement>
								}
								classWrapper={classes.inputContainer}
								description={permitLicenseNumberInput.description}
							/>

							<div className={pageUtils.classes.fieldsContainer}>
								<DateFilterInput
									id={permitExpiryDateInput.name}
									label={permitExpiryDateInput.label}
									value={permitExpiryDateInput.value}
									onChange={onSelectPermitExpiryDateInput}
									hasError={errors.includes(permitExpiryDateInput.name)}
									classWrapper={classes.inputContainer}
									required={permitExpiryDateInput.required}
									dateFormat="dd/MM/yyyy"
								/>
								{/*
							<TextInput
									type="date"
									id={permitExpiryDateInput.name}
									label={permitExpiryDateInput.label}
									value={permitExpiryDateInput.value}
									onChange={onSelectPermitExpiryDateInput}
									hasError={errors.includes(permitExpiryDateInput.name)}
									required={permitExpiryDateInput.required}
									ref={permitExpiryDateInput.ref as RefObject<HTMLInputElement>}
									classWrapper={classes.inputContainer}
								/>
							*/}
							</div>
						</section>
					</Collapse>
				)}

				{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3 && (
					<Collapse
						isOpened={
							subSteps !== undefined &&
							subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
						}
					>
						<section>
							<h4 className={pageUtils.classes.h4}>
								{
									pageAssets?.request_form_annualRegistrationCertificateForContractors_thirdSubStep_title
								}
							</h4>

							<p>
								{
									pageAssets?.request_form_annualRegistrationCertificateForContractors_thirdSubStep_description
								}
							</p>

							<br />

							{/* /////////////////  pesticideCertificates List  //////////////// */}
							{pesticideCertificatesList?.map(
								(pesticideCertificates, index) => {
									return renderPesticideCertificatesRow(
										pesticideCertificates,
										index
									)
								}
							)}

							{/* /////////////////  Add Button  //////////////// */}
							{pesticideCertificatesList?.length < 20 && (
								<a
									style={{ marginBottom: '12px' }}
									onClick={() => addPesticideCertificatesRow()}
									className={classes.button}
								>
									{pageAssets?.add}
								</a>
							)}
						</section>
					</Collapse>
				)}

				{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4 && (
					<Collapse
						isOpened={
							subSteps !== undefined &&
							subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
						}
					>
						<section>
							<h4 className={pageUtils.classes.h4}>
								{
									pageAssets?.request_form_annualRegistrationCertificateForContractors_fourthSubStep_title
								}
							</h4>

							<p>
								{
									pageAssets?.request_form_annualRegistrationCertificateForContractors_fourthSubStep_description
								}
							</p>

							<br />

							{/* /////////////////  vehicle Information List  //////////////// */}
							{vehicleInformationList?.map((vehicleInformation, index) => {
								return renderVehicleInformationRow(vehicleInformation, index)
							})}

							{/* /////////////////  Add Button  //////////////// */}
							{vehicleInformationList?.length < 40 && (
								<a
									style={{ marginBottom: '12px' }}
									onClick={() => addVehicleInformationRow()}
									className={classes.button}
								>
									{pageAssets?.add}
								</a>
							)}
						</section>
					</Collapse>
				)}

				{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS && (
					<Collapse
						isOpened={
							subSteps !== undefined &&
							subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
						}
					>
						<section className={pageUtils.classes.sectionNoPadding}>
							<h4 className={pageUtils.classes.h4}>
								{pageAssets?.request_form_required_documents}
							</h4>

							<RequestFiles
								subtitle={firstQuestion.label}
								name={firstQuestion.name}
								required
								files={firstQuestion.files}
								hasError={errors.includes(firstQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileFirstQuestion}
								subSteps={subSteps}
								maxFiles={firstQuestion?.numberMaxFiles}
								description={firstQuestion.description}
							></RequestFiles>

							<RequestFiles
								subtitle={secondQuestion.label}
								name={secondQuestion.name}
								required
								files={secondQuestion.files}
								hasError={errors.includes(secondQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileSecondQuestion}
								subSteps={subSteps}
								maxFiles={secondQuestion?.numberMaxFiles}
								description={secondQuestion.description}
							></RequestFiles>

							<RequestFiles
								subtitle={thirdQuestion.label}
								name={thirdQuestion.name}
								required
								files={thirdQuestion.files}
								hasError={errors.includes(thirdQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileThirdQuestion}
								subSteps={subSteps}
								maxFiles={thirdQuestion?.numberMaxFiles}
								description={thirdQuestion.description}
							></RequestFiles>

							<RequestFiles
								subtitle={fourthQuestion.label}
								name={fourthQuestion.name}
								required
								files={fourthQuestion.files}
								hasError={errors.includes(fourthQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileFourthQuestion}
								subSteps={subSteps}
								maxFiles={fourthQuestion?.numberMaxFiles}
								description={fourthQuestion.description}
							></RequestFiles>

							<RequestFiles
								subtitle={fifthQuestion.label}
								name={fifthQuestion.name}
								required={fifthQuestion.required}
								files={fifthQuestion.files}
								hasError={errors.includes(fifthQuestion.name)}
								removeSectionMarginTop
								onSetHasError={onSetErrorFromFileComponent}
								onSetFiles={onSelectFileFifthQuestion}
								subSteps={subSteps}
								maxFiles={fifthQuestion?.numberMaxFiles}
								description={fifthQuestion.description}
							></RequestFiles>
						</section>
					</Collapse>
				)}
			</>
		)
	}

export default AnnualRegistrationCertificateForContractorsForm
