import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	RadioList,
	CheckboxList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse } from 'react-collapse'
import {
	Direction,
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	RequestCategoryEnum,
	requestCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import TextAreaInput from './inputs/textarea.inputs'
import Text from '@components/ui/text'
import { joinClasses } from '@utils/styles'
import RequestFiles from '@components/ui/requestFiles'
import MaskedTextInput from './inputs/textWithValidation.inputs'
import { PHONE_NUMBER_REGEX } from '@pages/auth/profile/__index.utils'
import { MASKS } from '@pages/auth/__index.utils'
import Notification from '@components/ui/notification'
import RadioInput from './inputs/radio.input'
import CheckboxInput from './inputs/checkbox.input'
import BlueBulletsList, {
	BlueBulletsLabel
} from '@components/ui/blueBulletsList'
import { getAddressByCoordinates } from '@components/ui/addressForm/__index.utils'
import { getFilesFromStore, isObjectEmpty } from '@utils/methods'
import { CustomDate } from '@services/models'
import moment from 'moment'
import DateFilterInput from './inputs/datefilter.input'

const TemporaryPesticideApplicationPermitForIndividualsForm: FC<RequestFormPDFProps> =
	({
		inputs,
		errors,
		subSteps,
		inputFiles,
		isSummaryStep,
		steps,
		toPrint,
		filesInputs,
		formPosition,
		onCustomError,
		onSetInputFiles,
		onSetSubSteps,
		onFixError,
		onSetInputs,
		setCurrentStep
	}) => {
		const {
			authUser,
			pageAssets,
			language,
			UpdateRequestInputsArray,
			UpdateRequestInputFilesArray,
			UpdateRequestInputsErrorsArray
		} = requestForm()

		const answers = useAppSelector((state) => state.request.configs)
		const location = useAppSelector((state) => state.request.location)
		const seperator = ', '

		const [infestationListType, setInfestationListType] =
			useState<CheckboxList[]>()
		const [locationOfInfestationList, setLocationOfInfestationList] =
			useState<CheckboxList[]>()
		const [spaceToBeTreatedList, setSpaceToBeTreatedList] =
			useState<CheckboxList[]>()
		const [controlMethodsList, setControlMethodsList] =
			useState<CheckboxList[]>()
		const [firstQuestion, setFirstQuestion] = useState<RequestInputs>({
			name: 'contractorCompanyName',
			label: pageAssets?.request_form_temporaryUseOfPesticide_company_name,
			labelKey: 'request_form_temporaryUseOfPesticide_company_name',
			value: answers.contractorCompanyName ?? '',
			required: true,
			ref: useRef<HTMLInputElement>(null)
		})

		const [secondQuestion, setSecondQuestion] = useState<RequestInputs>({
			name: 'contractorPermisNumber',
			label: pageAssets?.request_form_temporaryUseOfPesticide_license_number,
			labelKey: 'request_form_temporaryUseOfPesticide_license_number',
			value: answers.contractorPermisNumber ?? '',
			required: true,
			ref: useRef<HTMLInputElement>(null)
		})

		const [thirdQuestion, setThirdQuestion] = useState<RequestInputs>({
			name: 'contractorTelephone',
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.contractorTelephone ?? '',
			required: true,
			ref: useRef<HTMLInputElement>(null)
		})

		const [fourthQuestion, setFourthQuestion] = useState<RequestInputs>({
			name: 'typeOfInfestation',
			value: answers.typeOfInfestation ?? '',
			label: pageAssets?.request_form_temporaryUseOfPesticide_infestation_type,
			labelKey: 'request_form_temporaryUseOfPesticide_infestation_type',
			required: true,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			ref: useRef<HTMLInputElement>(null)
		})

		const [fifthQuestion, setFifthQuestion] = useState<RequestInputs>({
			name: 'locationOfInfestation',
			value: answers.locationOfInfestation ?? '',
			label:
				pageAssets?.request_form_temporaryUseOfPesticide_location_of_infestation,
			labelKey: 'request_form_temporaryUseOfPesticide_location_of_infestation',
			required: true,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			ref: useRef<HTMLInputElement>(null)
		})
		const [sixthQuestion, setSixthQuestion] = useState<RequestInputs>({
			name: 'spaceToBeTreated',
			label: pageAssets?.request_form_temporaryUseOfPesticide_space_to_treat,
			labelKey: 'request_form_temporaryUseOfPesticide_space_to_treat',
			value: answers.spaceToBeTreated ?? '',
			required: true,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			ref: useRef<HTMLInputElement>(null)
		})

		const [seventhQuestion, setSeventhQuestion] = useState<RequestInputs>({
			name: 'areaToBeTreated',
			label:
				pageAssets?.request_form_temporaryUseOfPesticide_area_to_be_treated,
			labelKey: 'request_form_temporaryUseOfPesticide_area_to_be_treated',
			value: answers.areaToBeTreated ?? '',
			required: true,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			ref: useRef<HTMLInputElement>(null)
		})
		const [eighthQuestion, setEighthQuestion] = useState<RequestInputs>({
			name: 'expectedDateOfApplication',
			label:
				pageAssets?.request_form_temporaryUseOfPesticide_expected_date_of_application,
			labelKey:
				'request_form_temporaryUseOfPesticide_expected_date_of_application',
			value: answers?.expectedDateOfApplication ?? '',
			required: true,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			ref: useRef<HTMLInputElement>(null),
			extraParams: {
				type: 'date',
				min: moment().businessAdd(5).format('YYYY-MM-DD'),
				max: '',
				customErrorText: pageAssets?.label_enter_future_date
			}
		})
		const [ninethQuestion, setNinethQuestion] = useState<RequestInputs>({
			name: 'historyOfTheProblem',
			label:
				pageAssets?.request_form_temporaryUseOfPesticide_history_of_the_problem,
			labelKey: 'request_form_temporaryUseOfPesticide_history_of_the_problem',
			value: answers.historyOfTheProblem ?? '',
			required: true,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			ref: useRef<HTMLTextAreaElement>(null)
		})
		const [tenthQuestion, setTenthQuestion] = useState<RequestInputs>({
			name: 'controlMethodsAlreadyApplied',
			label:
				pageAssets?.request_form_temporaryUseOfPesticide_control_methods_already_applied,
			labelKey:
				'request_form_temporaryUseOfPesticide_control_methods_already_applied',
			description:
				pageAssets?.request_form_temporaryUseOfPesticide_control_methods_already_applied_description,
			value: answers.controlMethodsAlreadyApplied ?? '',
			required: true,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			ref: useRef<HTMLInputElement>(null)
		})

		const [eleventhQuestion, setEleventhQuestion] = useState<RequestInputs>({
			name: 'specifyControlMethodsApplied',
			label:
				pageAssets?.request_form_temporaryUseOfPesticide_specify_control_methods_applied,
			labelKey:
				'request_form_temporaryUseOfPesticide_specify_control_methods_applied',
			value: answers.specifyControlMethodsApplied ?? '',
			required: false,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			ref: useRef<HTMLTextAreaElement>(null)
		})
		const [twelfthQuestion, setTwelfthQuestion] = useState<RequestInputs>({
			name: 'productTradeName',
			label:
				pageAssets?.request_form_temporaryUseOfPesticide_product_trade_name,
			labelKey: 'request_form_temporaryUseOfPesticide_product_trade_name',
			value: answers.productTradeName ?? '',
			required: true,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			ref: useRef<HTMLInputElement>(null)
		})

		const [thirteenthQuestion, setThirteenthQuestion] = useState<RequestInputs>(
			{
				name: 'certificationNumber',
				label:
					pageAssets?.request_form_temporaryUseOfPesticide_certification_number,
				labelKey: 'request_form_temporaryUseOfPesticide_certification_number',
				description:
					pageAssets?.request_form_temporaryUseOfPesticide_certification_number_description,
				value: answers.certificationNumber ?? '',
				required: true,
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				ref: useRef<HTMLInputElement>(null)
			}
		)

		const [fouteenthQuestion, setFouteenthQuestion] = useState<RequestInputs>({
			name: 'activeIngredient',
			label: pageAssets?.request_form_temporaryUseOfPesticide_active_ingredient,
			labelKey: 'request_form_temporaryUseOfPesticide_active_ingredient',
			description:
				pageAssets?.request_form_temporaryUseOfPesticide_active_ingredient_description,
			value: answers.activeIngredient ?? '',
			required: true,
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
			ref: useRef<HTMLInputElement>(null)
		})

		const [propertyAddressSameAsApplicant, setPropertyAddressSameAsApplicant] =
			useState<RequestInputs>({
				name: 'propertyAddressSameAsApplicant',
				label: pageAssets.request_form_is_address_correct,
				labelKey: 'request_form_is_address_correct',
				value: location.useDefaultUserLocation ? 'true' : 'false',
				required: false,
				ref: useRef<HTMLInputElement>(null)
			})

		const [propertyAddressInput, setpropertyAddressInput] =
			useState<RequestInputs>({
				name: 'propertyAddress',
				value: location.address,
				label: '',
				labelKey: '',
				required: false
			})

		const [propertyPostalCodeInput, setPropertyPostalCodeInput] =
			useState<RequestInputs>({
				name: 'propertyPostalCode',
				value:
					location.postalCode != ''
						? location.postalCode
						: authUser?.profile?.address.postalCode ?? '',
				label: '',
				labelKey: '',
				required: false
			})

		const [propertyExCityInput, setPropertyExCityInput] =
			useState<RequestInputs>({
				name: 'propertyExCity',
				value:
					location.city !== ''
						? location.city
						: authUser?.profile?.address.city ?? '',
				label: '',
				labelKey: '',
				required: false
			})

		const [propertyLotNumberInput, setPropertyLotNumberInput] =
			useState<RequestInputs>({
				name: 'propertyLotNumber',
				value: answers.buildingCadastralNumber ?? '',
				label: '',
				labelKey: '',
				required: false
			})

		const [typeOfInfestationOtherInput, setTypeOfInfestationOtherInput] =
			useState<RequestInputs>({
				name: 'typeOfInfestationOther',
				label:
					pageAssets?.request_form_temporaryUseOfPesticide_infestation_type,
				labelKey: 'request_form_temporaryUseOfPesticide_infestation_type',
				value: answers.typeOfInfestationOther ?? '',
				required: false,
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				ref: useRef<HTMLInputElement>(null)
			})

		const [spaceToBeTreatedOtherInput, setSpaceToBeTreatedOtherInput] =
			useState<RequestInputs>({
				name: 'spaceToBeTreatedOther',
				label: pageAssets?.request_form_temporaryUseOfPesticide_space_to_treat,
				labelKey: 'request_form_temporaryUseOfPesticide_space_to_treat',
				value: answers.spaceToBeTreatedOther ?? '',
				required: false,
				subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				ref: useRef<HTMLInputElement>(null)
			})

		//----------------------------------------------------------------
		//----------------------- DOCUMENTS INPUTS -----------------------
		//----------------------------------------------------------------

		// Photo(s) ou autre(s) document(s)
		const [fifteenthQuestion, setFifteenthQuestion] =
			useState<RequestInputFiles>({
				name: 'certificateFromCertifiedCompanyInput',
				label:
					pageAssets?.request_form_temporaryUseOfPesticide_certificate_from_a_certified_company,
				labelKey:
					'request_form_temporaryUseOfPesticide_certificate_from_a_certified_company',
				description:
					pageAssets?.request_form_temporaryUseOfPesticide_certificate_from_a_certified_company_description,
				files: new Set<File>(
					filesInputs?.find(
						(el) => el.name == 'certificateFromCertifiedCompanyInput'
					)?.files || []
				),
				required: true,
				numberMaxFiles: 4,
				category: requestCategories.other
			})

		const [sixteenthQuestion, setSixteenthQuestion] =
			useState<RequestInputFiles>({
				name: 'infestationPhotosInput',
				label:
					pageAssets?.request_form_temporaryUseOfPesticide_infestation_photos,
				labelKey: 'request_form_temporaryUseOfPesticide_infestation_photos',
				description:
					pageAssets?.request_form_temporaryUseOfPesticide_infestation_photos_description,
				files: new Set<File>(
					filesInputs?.find((el) => el.name == 'infestationPhotosInput')
						?.files || []
				),
				required: true,
				numberMaxFiles: 4,
				category: requestCategories.other
			})

		const [seventeenthQuestion, setSeventeenthQuestion] =
			useState<RequestInputFiles>({
				name: 'otherInfestationDocumentsInput',
				label: pageAssets?.request_form_temporaryUseOfPesticide_other_documents,
				labelKey: 'request_form_temporaryUseOfPesticide_other_documents',
				description:
					pageAssets?.request_form_temporaryUseOfPesticide_other_documents_description,
				files: new Set<File>(
					filesInputs?.find((el) => el.name == 'otherInfestationDocumentsInput')
						?.files || []
				),
				required: false,
				numberMaxFiles: 4,
				category: requestCategories.other
			})

		const [hasWrongDate, setHasWrongDate] = useState<boolean>(false)

		// const fifteenthQuestionListDescription: BlueBulletsLabel[] = [
		// 	{
		// 		name: pageAssets?.request_form_temporaryUseOfPesticide_fifteenthQuestion_date_of_the_visit
		// 	},
		// 	{
		// 		name: pageAssets?.request_form_temporaryUseOfPesticide_fifteenthQuestion_land_address
		// 	},
		// 	{
		// 		name: pageAssets?.request_form_temporaryUseOfPesticide_fifteenthQuestion_location_of_infestation
		// 	},
		// 	{
		// 		name: pageAssets?.request_form_temporaryUseOfPesticide_fifteenthQuestion_type_of_infestation
		// 	},
		// 	{
		// 		name: pageAssets?.request_form_temporaryUseOfPesticide_product_trade_name
		// 	},
		// 	{
		// 		name: pageAssets?.request_form_temporaryUseOfPesticide_certification_number
		// 	},
		// 	{
		// 		name: pageAssets?.request_form_temporaryUseOfPesticide_fifteenthQuestion_name_of_certified_company
		// 	},
		// 	{
		// 		name: pageAssets?.request_form_temporaryUseOfPesticide_fifteenthQuestion_annual_registration_certificate_number
		// 	},
		// 	{
		// 		name: pageAssets?.request_form_temporaryUseOfPesticide_fifteenthQuestion_employee_of_the_company
		// 	}
		// ]

		const typeOfInfestationList = pageUtils.addLabelToList(
			pageUtils.infestationListTypeAndValues,
			pageAssets
		)
		const locationOfInfestation = pageUtils.addLabelToList(
			pageUtils.locationOfInfestationListAndValues,
			pageAssets
		)
		const spaceToTreatedList = pageUtils.addLabelToList(
			pageUtils.spaceToBeTreatedListAndValues,
			pageAssets
		)
		const controlMethodList = pageUtils.addLabelToList(
			pageUtils.controlMethodsAlreadyAppliedListValues,
			pageAssets
		)

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, firstQuestion))
		}, [firstQuestion])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, secondQuestion))
		}, [secondQuestion])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, thirdQuestion))
		}, [thirdQuestion])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, fourthQuestion))
		}, [fourthQuestion])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, fifthQuestion))
		}, [fifthQuestion])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, sixthQuestion))
		}, [sixthQuestion])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, seventhQuestion))
		}, [seventhQuestion])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, eighthQuestion))
		}, [eighthQuestion])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, ninethQuestion))
		}, [ninethQuestion])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, tenthQuestion))
		}, [tenthQuestion])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, eleventhQuestion))
		}, [eleventhQuestion])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, twelfthQuestion))
		}, [twelfthQuestion])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, thirteenthQuestion))
		}, [thirteenthQuestion])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, fouteenthQuestion))
		}, [fouteenthQuestion])

		useEffect(() => {
			onSetInputFiles(
				UpdateRequestInputFilesArray(inputFiles, fifteenthQuestion)
			)
		}, [fifteenthQuestion])

		useEffect(() => {
			onSetInputFiles(
				UpdateRequestInputFilesArray(inputFiles, sixteenthQuestion)
			)
		}, [sixteenthQuestion])

		useEffect(() => {
			onSetInputFiles(
				UpdateRequestInputFilesArray(inputFiles, seventeenthQuestion)
			)
		}, [seventeenthQuestion])

		useEffect(() => {
			onSetInputs(
				UpdateRequestInputsArray(inputs!, propertyAddressSameAsApplicant)
			)
		}, [propertyAddressSameAsApplicant])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, typeOfInfestationOtherInput))
		}, [typeOfInfestationOtherInput])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, spaceToBeTreatedOtherInput))
		}, [spaceToBeTreatedOtherInput])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, propertyAddressInput))
		}, [propertyAddressInput])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, propertyPostalCodeInput))
		}, [propertyPostalCodeInput])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, propertyExCityInput))
		}, [propertyExCityInput])

		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs, propertyLotNumberInput))
		}, [propertyLotNumberInput])

		const seventhQuestionList: RadioList[] = [
			{
				label: pageAssets?.label_less_than_one_meter,
				value: REQUEST_CONFIGS_KEYS.areaTobeTreated.lessThanOneM,
				key: 'label_less_than_one_meter'
			},
			{
				label: pageAssets?.label_one_to_five_metres,
				value: REQUEST_CONFIGS_KEYS.areaTobeTreated.oneToFiveM,
				key: 'label_one_to_five_metres'
			},
			{
				label: pageAssets?.label_five_to_ten_metres,
				value: REQUEST_CONFIGS_KEYS.areaTobeTreated.fiveToTenM,
				key: 'label_five_to_ten_metres'
			},
			{
				label: pageAssets?.label_more_than_ten_metres,
				value: REQUEST_CONFIGS_KEYS.areaTobeTreated.moreThanTenM,
				key: 'label_more_than_ten_metres'
			}
		]

		useEffect(() => {
			if (
				propertyLotNumberInput.value == '' &&
				isObjectEmpty(location.coordinates)
			) {
				const getData = async () => {
					const res = await getAddressByCoordinates(location.coordinates)
					setPropertyLotNumberInput({
						...propertyLotNumberInput,
						value: res?.data.features[0].properties.cadastre
					})
				}
				getData()
			}
		}, [location])

		useEffect(() => {
			setInfestationListType(typeOfInfestationList)

			setLocationOfInfestationList(locationOfInfestation)

			setSpaceToBeTreatedList(spaceToTreatedList)

			setControlMethodsList(controlMethodList)
		}, [language])

		const onSelectFirstQuestion = (value: string) => {
			setFirstQuestion({ ...firstQuestion, value })

			onFixError(UpdateRequestInputsErrorsArray(errors, firstQuestion.name))
		}

		const onSelectSecondQuestion = (value: string) => {
			setSecondQuestion({ ...secondQuestion, value })

			onFixError(UpdateRequestInputsErrorsArray(errors, secondQuestion.name))
		}

		const onSelectThirdQuestion = (value) => {
			setThirdQuestion({ ...thirdQuestion, value })

			onFixError(UpdateRequestInputsErrorsArray(errors, thirdQuestion.name))
		}

		const onSelectSeventhQuestion = (value) => {
			setSeventhQuestion({ ...seventhQuestion, value })

			onFixError(UpdateRequestInputsErrorsArray(errors, seventhQuestion.name))
		}

		const onSelectEighthQuestion = (value) => {
			if (Number(value.split('-', 2)[0]).toString().length > 4) {
				return
			}

			setEighthQuestion({ ...eighthQuestion, value })

			onFixError(UpdateRequestInputsErrorsArray(errors, eighthQuestion.name))
		}

		const onSelectNinethQuestion = (value) => {
			setNinethQuestion({ ...ninethQuestion, value })

			onFixError(UpdateRequestInputsErrorsArray(errors, ninethQuestion.name))
		}

		const onSelectEleventhQuestion = (value) => {
			setEleventhQuestion({ ...eleventhQuestion, value })

			onFixError(UpdateRequestInputsErrorsArray(errors, eleventhQuestion.name))
		}

		const onSelectTwelfthQuestion = (value) => {
			setTwelfthQuestion({ ...twelfthQuestion, value })

			onFixError(UpdateRequestInputsErrorsArray(errors, twelfthQuestion.name))
		}

		const onSelectThirteenthQuestion = (value) => {
			setThirteenthQuestion({ ...thirteenthQuestion, value })

			onFixError(
				UpdateRequestInputsErrorsArray(errors, thirteenthQuestion.name)
			)
		}

		const onSelectFouteenthQuestion = (value) => {
			setFouteenthQuestion({ ...fouteenthQuestion, value })

			onFixError(UpdateRequestInputsErrorsArray(errors, fouteenthQuestion.name))
		}

		const onChangeFourthQuestion = (value: string, key: string) => {
			pageUtils.handleCheckboxWithOthers(
				value,
				fourthQuestion,
				setFourthQuestion,
				typeOfInfestationOtherInput,
				setTypeOfInfestationOtherInput,
				infestationListType!
			)

			onFixError(
				errors.filter(
					(error) =>
						![fourthQuestion.name, typeOfInfestationOtherInput.name].includes(
							error
						)
				)
			)
		}

		const onChangeFifthQuestion = (value: string, key: string) => {
			let newValue
			if (fifthQuestion.value?.includes(value)) {
				newValue = pageUtils.removeStrFromStrArray(value, fifthQuestion.value)
			} else {
				newValue =
					fifthQuestion.value == '' ? value : fifthQuestion.value + `, ${value}`
			}

			setFifthQuestion({
				...fifthQuestion,
				value: newValue
			})
			onFixError(UpdateRequestInputsErrorsArray(errors, fifthQuestion.name))
		}

		const onChangeSixthQuestion = (value: string, key: string) => {
			pageUtils.handleCheckboxWithOthers(
				value,
				sixthQuestion,
				setSixthQuestion,
				spaceToBeTreatedOtherInput,
				setSpaceToBeTreatedOtherInput,
				spaceToBeTreatedList!
			)

			onFixError(
				errors.filter(
					(error) =>
						![sixthQuestion.name, spaceToBeTreatedOtherInput.name].includes(
							error
						)
				)
			)
		}

		const onChangeTenthQuestion = (value: string, key: string) => {
			let newValue
			if (tenthQuestion.value?.includes(value)) {
				newValue = pageUtils.removeStrFromStrArray(value, tenthQuestion.value)
			} else {
				newValue =
					tenthQuestion.value == '' ? value : tenthQuestion.value + `, ${value}`
			}

			setTenthQuestion({
				...tenthQuestion,
				value: newValue
			})
			onFixError(UpdateRequestInputsErrorsArray(errors, tenthQuestion.name))
		}

		const onSelectFileFifteenthQuestion = (files: Set<File>) => {
			setFifteenthQuestion({
				...fifteenthQuestion,
				files
			})

			onFixError(UpdateRequestInputsErrorsArray(errors, fifteenthQuestion.name))
		}
		const onSelectFileSixteenthQuestion = (files: Set<File>) => {
			setSixteenthQuestion({
				...sixteenthQuestion,
				files
			})

			onFixError(UpdateRequestInputsErrorsArray(errors, sixteenthQuestion.name))
		}
		const onSelectFileSeventeenthQuestion = (files: Set<File>) => {
			setSeventeenthQuestion({
				...seventeenthQuestion,
				files
			})

			onFixError(
				UpdateRequestInputsErrorsArray(errors, seventeenthQuestion.name)
			)
		}

		const onSetErrorFromFileComponent = (
			hasErrorInComponent: boolean,
			inputName: string
		) => {
			if (hasErrorInComponent) {
				if (!errors.includes(inputName)) {
					onFixError((oldErrors) => [...oldErrors, inputName])
				}

				return
			}

			onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
		}

		useEffect(() => {
			onSetSubSteps({
				map: true,
				details_1: true,
				details_2: true,
				requiredDocument: true,
				position:
					subSteps?.position && String(subSteps?.position) !== ''
						? subSteps?.position
						: REQUEST_TN_DETAILS_STEPS_NAME.MAP,
				steps: [
					REQUEST_TN_DETAILS_STEPS_NAME.MAP,
					REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
					REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
					REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
				]
			})
		}, [])

		useEffect(() => {
			// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
			// ----------------------------
			// certificateFromCertifiedCompanyInput input
			if (
				!!answers.certificateFromCertifiedCompanyInput &&
				fifteenthQuestion.files.size == 0
			) {
				getFilesFromStore('certificateFromCertifiedCompanyInput', answers).then(
					(result: Set<File>) => {
						setFifteenthQuestion({
							...fifteenthQuestion,
							files: result
						})
					}
				)
			}

			// infestationPhotosInput
			if (
				!!answers.infestationPhotosInput &&
				sixteenthQuestion.files.size == 0
			) {
				getFilesFromStore('infestationPhotosInput', answers).then(
					(result: Set<File>) => {
						setSixteenthQuestion({
							...sixteenthQuestion,
							files: result
						})
					}
				)
			}

			// secondContractorBill input
			if (
				!!answers.otherInfestationDocumentsInput &&
				seventeenthQuestion.files.size == 0
			) {
				getFilesFromStore('otherInfestationDocumentsInput', answers).then(
					(result: Set<File>) => {
						setSeventeenthQuestion({
							...seventeenthQuestion,
							files: result
						})
					}
				)
			}
		}, [])

		const backToForm = (step: number, subStep: string) => {
			if (setCurrentStep && onSetSubSteps && subSteps) {
				setCurrentStep(step)
				onSetSubSteps({
					...subSteps,
					position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
				})
			}
		}

		const getLabelsFromString = (
			list: pageUtils.CheckboxType[],
			answer?: string
		): string[] => {
			return list
				.filter((element) => answer?.includes(element.value))
				.map((item) => item.key)
		}

		const getLabelFromList = (list, value) => {
			return list?.find((element) => element.value == value?.toString())?.label
		}

		if (isSummaryStep) {
			return (
				<div>
					<section
						className={pageUtils.classes.noMargin}
						style={{ padding: '0px' }}
					>
						{subSteps?.details_1 && (
							<>
								<div>
									<a
										style={{
											display: toPrint ? 'none' : 'flex',
											float: 'right'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
									<h4 className={pageUtils.classes.h4}>
										{
											pageAssets?.request_form_temporaryUseOfPesticide_information_about_the_entrepreneur
										}
									</h4>
								</div>

								<div>
									<strong>{pageAssets[firstQuestion.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.contractorCompanyName}
									</span>
								</div>
								<div>
									<strong>{pageAssets[secondQuestion.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.contractorPermisNumber}
									</span>
								</div>
								<div>
									<strong>{pageAssets[thirdQuestion.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.contractorTelephone}
									</span>
								</div>
							</>
						)}

						{subSteps?.details_2 && (
							<>
								<div>
									<a
										style={{
											display: toPrint ? 'none' : 'flex',
											float: 'right'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
									<h4 className={pageUtils.classes.h4}>
										{
											pageAssets?.request_form_temporaryUseOfPesticide_infestation_information
										}
									</h4>
								</div>

								<div>
									<strong>{pageAssets[fourthQuestion.labelKey]}</strong>
									<br />
									<span
										className={joinClasses([
											pageUtils.classes.answer,
											toPrint ? pageUtils.classes.removeMargins : ''
										])}
									>
										{getLabelsFromString(
											pageUtils.infestationListTypeAndValues,
											answers?.typeOfInfestation
										).map((answerLabel, index) => {
											return (
												<p
													className={pageUtils.classes.answer}
													key={`${answerLabel}-${index}-infestactionType`}
												>
													{pageAssets[answerLabel]}
												</p>
											)
										})}
										{answers?.typeOfInfestationOther
											?.split(seperator)
											.map((answer, index) => {
												return (
													<p
														className={pageUtils.classes.answer}
														key={`${answer}-${index}-infestactionType`}
													>
														{answer}
													</p>
												)
											})}
									</span>
								</div>
								{toPrint && <br />}
								<div>
									<strong>{pageAssets[fifthQuestion.labelKey]}</strong>
									<br />
									<span
										className={joinClasses([
											pageUtils.classes.answer,
											toPrint ? pageUtils.classes.removeMargins : ''
										])}
									>
										{getLabelsFromString(
											pageUtils.locationOfInfestationListAndValues,
											answers.locationOfInfestation
										).map((answerLabel, index) => {
											return (
												<p
													className={pageUtils.classes.answer}
													key={`${answerLabel}-${index}-locationOfInfestation`}
												>
													{pageAssets[answerLabel]}
												</p>
											)
										})}
									</span>
								</div>
								<div>
									<strong>{pageAssets[sixthQuestion.labelKey]}</strong>
									<br />
									<span
										className={joinClasses([
											pageUtils.classes.answer,
											toPrint ? pageUtils.classes.removeMargins : ''
										])}
									>
										{getLabelsFromString(
											pageUtils.spaceToBeTreatedListAndValues,
											answers.spaceToBeTreated
										).map((answerLabel, index) => {
											return (
												<p
													className={pageUtils.classes.answer}
													key={`${answerLabel}-${index}-spaceToBeTreated`}
												>
													{pageAssets[answerLabel]}
												</p>
											)
										})}
										{answers?.spaceToBeTreatedOther
											?.split(seperator)
											.map((answer, index) => {
												return (
													<p
														className={pageUtils.classes.answer}
														key={`${answer}-${index}-infestactionType`}
													>
														{answer}
													</p>
												)
											})}
									</span>
								</div>
								<div>
									<strong>{pageAssets[seventhQuestion.labelKey]}</strong>
									<br />
									<span
										className={joinClasses([
											pageUtils.classes.answer,
											toPrint ? pageUtils.classes.minusMargin : ''
										])}
									>
										<Text
											content={getLabelFromList(
												seventhQuestionList,
												answers?.areaToBeTreated
											)}
										/>
									</span>
								</div>
								<div>
									<strong>{pageAssets[eighthQuestion.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{pageUtils.getLocaleDate(
											answers.expectedDateOfApplication ?? ''
										)}
									</span>
								</div>

								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_temporaryUseOfPesticide_infestation_historyn
									}
								</h5>
								<div>
									<strong>{pageAssets[ninethQuestion.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.historyOfTheProblem}
									</span>
								</div>
								<div>
									<strong>{pageAssets[tenthQuestion.labelKey]}</strong>
									<br />
									<span
										className={joinClasses([
											pageUtils.classes.answer,
											toPrint ? pageUtils.classes.removeMargins : ''
										])}
									>
										{getLabelsFromString(
											pageUtils.controlMethodsAlreadyAppliedListValues,
											answers.controlMethodsAlreadyApplied
										).map((answerLabel, index) => {
											return (
												<p
													className={pageUtils.classes.answer}
													key={`${answerLabel}-${index}-controlMethodsAlreadyApplied`}
												>
													{pageAssets[answerLabel]}
												</p>
											)
										})}
									</span>
								</div>
								{answers.specifyControlMethodsApplied && (
									<div>
										<strong>{pageAssets[eleventhQuestion.labelKey]}</strong>
										<br />
										<span className={pageUtils.classes.answer}>
											{answers.specifyControlMethodsApplied}
										</span>
									</div>
								)}

								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_temporaryUseOfPesticide_product_used_to_control_infestation
									}
								</h5>
								<div>
									<strong>{pageAssets[twelfthQuestion.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.productTradeName}
									</span>
								</div>
								<div>
									<strong>{pageAssets[thirteenthQuestion.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.certificationNumber}
									</span>
								</div>
								<div>
									<strong>{pageAssets[fouteenthQuestion.labelKey]}</strong>
									<br />
									<span className={pageUtils.classes.answer}>
										{answers.activeIngredient}
									</span>
								</div>
							</>
						)}

						{subSteps?.requiredDocument && (
							<>
								<div style={{ marginTop: '40px' }}>
									<a
										style={{
											display: toPrint ? 'none' : 'flex',
											float: 'right'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_required_documents}
									</h4>
								</div>
								{!!fifteenthQuestion.files.size && (
									<RequestFiles
										subtitle={fifteenthQuestion.label}
										name={fifteenthQuestion.name}
										required={fifteenthQuestion.required}
										files={fifteenthQuestion.files}
										hasError={errors.includes(fifteenthQuestion.name)}
										removeSectionMarginTop
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileSixteenthQuestion}
										maxFiles={fifteenthQuestion.numberFilesRequired}
										isSummary
									>
										<></>
									</RequestFiles>
								)}
								{!!sixteenthQuestion.files.size && (
									<RequestFiles
										subtitle={sixteenthQuestion.label}
										name={sixteenthQuestion.name}
										required={sixteenthQuestion.required}
										files={sixteenthQuestion.files}
										hasError={errors.includes(sixteenthQuestion.name)}
										removeSectionMarginTop
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileSixteenthQuestion}
										maxFiles={sixteenthQuestion.numberFilesRequired}
										isSummary
									>
										<></>
									</RequestFiles>
								)}
								{!!seventeenthQuestion.files.size && (
									<RequestFiles
										subtitle={seventeenthQuestion.label}
										name={seventeenthQuestion.name}
										required={seventeenthQuestion.required}
										files={seventeenthQuestion.files}
										hasError={errors.includes(seventeenthQuestion.name)}
										removeSectionMarginTop
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileSixteenthQuestion}
										maxFiles={seventeenthQuestion.numberFilesRequired}
										isSummary
									>
										<></>
									</RequestFiles>
								)}
							</>
						)}
					</section>
				</div>
			)
		}

		return (
			<>
				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{
								pageAssets?.request_form_temporaryUseOfPesticide_information_about_the_entrepreneur
							}
						</h4>
						<TextInput
							id={firstQuestion.name}
							label={firstQuestion.label}
							value={firstQuestion.value}
							onChange={onSelectFirstQuestion}
							hasError={errors.includes(firstQuestion.name)}
							required={firstQuestion.required}
							ref={firstQuestion.ref as RefObject<HTMLInputElement>}
						/>
						<div className={pageUtils.classes.fieldWrapper}>
							<TextInput
								id={secondQuestion.name}
								label={secondQuestion.label}
								value={secondQuestion.value}
								onChange={onSelectSecondQuestion}
								hasError={errors.includes(secondQuestion.name)}
								required={secondQuestion.required}
								ref={secondQuestion.ref as RefObject<HTMLInputElement>}
								autoComplete="off"
								type="email"
							/>
						</div>
						<div className={pageUtils.classes.fieldWrapper}>
							<MaskedTextInput
								id={thirdQuestion.name}
								label={thirdQuestion.label}
								value={thirdQuestion.value}
								onChange={onSelectThirdQuestion}
								hasError={errors?.includes(thirdQuestion.name)}
								type="tel"
								min={10}
								max={14}
								mask={MASKS.phoneNumber}
								placeholder={MASKS.phoneNumber}
								errors={errors ? errors : []}
								setError={onFixError}
								schema={PHONE_NUMBER_REGEX}
								required={thirdQuestion.required}
								ref={thirdQuestion.ref as RefObject<HTMLInputElement>}
							/>
						</div>
					</section>
				</Collapse>

				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
				>
					<section>
						<h3 className={pageUtils.classes.h4}>
							{
								pageAssets?.request_form_temporaryUseOfPesticide_infestation_information
							}
						</h3>
						<Notification
							text={
								pageAssets?.request_form_temporaryUseOfPesticide_notification
							}
							type="info"
							hasHtml
						/>
						<CheckboxInput
							name={fourthQuestion.name}
							label={fourthQuestion.label}
							value={`${fourthQuestion.value}`}
							list={infestationListType}
							onChange={onChangeFourthQuestion}
							required={fourthQuestion.required}
							hasError={errors.includes(fourthQuestion.name)}
							ref={fourthQuestion.ref as RefObject<HTMLInputElement>}
							errors={errors}
							shouldNotDivide
							hasBackgroundWrapper
							onFixError={onFixError}
							newCheckValue
							others={
								pageUtils.infestationListTypeAndValues[
									pageUtils.infestationListTypeAndValues.length - 1
								]
							}
							otherInput={typeOfInfestationOtherInput}
							setOtherInput={setTypeOfInfestationOtherInput}
							UpdateRequestInputsErrorsArray={UpdateRequestInputsErrorsArray}
						/>
						<CheckboxInput
							name={fifthQuestion.name}
							label={fifthQuestion.label}
							value={`${fifthQuestion.value}`}
							list={locationOfInfestationList}
							onChange={onChangeFifthQuestion}
							required={fifthQuestion.required}
							hasError={errors.includes(fifthQuestion.name)}
							ref={fifthQuestion.ref as RefObject<HTMLInputElement>}
							errors={errors}
							shouldNotDivide
							newCheckValue
							hasBackgroundWrapper
							onFixError={onFixError}
						/>
						<CheckboxInput
							name={sixthQuestion.name}
							label={sixthQuestion.label}
							value={`${sixthQuestion.value}`}
							list={spaceToBeTreatedList}
							onChange={onChangeSixthQuestion}
							required={sixthQuestion.required}
							hasError={errors.includes(sixthQuestion.name)}
							ref={sixthQuestion.ref as RefObject<HTMLInputElement>}
							errors={errors}
							shouldNotDivide
							hasBackgroundWrapper
							onFixError={onFixError}
							newCheckValue
							others={
								pageUtils.spaceToBeTreatedListAndValues[
									pageUtils.spaceToBeTreatedListAndValues.length - 1
								]
							}
							otherInput={spaceToBeTreatedOtherInput}
							setOtherInput={setSpaceToBeTreatedOtherInput}
							UpdateRequestInputsErrorsArray={UpdateRequestInputsErrorsArray}
						/>
						<RadioInput
							name={seventhQuestion.name}
							label={seventhQuestion.label}
							value={seventhQuestion.value}
							list={seventhQuestionList}
							required={seventhQuestion.required}
							direction={Direction.vertical}
							hasError={errors!.includes(seventhQuestion.name)}
							onChange={onSelectSeventhQuestion}
							hasBackgroundWrapper
							ref={seventhQuestion.ref as RefObject<HTMLInputElement>}
						/>

						<div className={pageUtils.classes.fieldWrapper}>
							<DateFilterInput
								id={eighthQuestion.name}
								label={eighthQuestion.label}
								value={eighthQuestion.value}
								onChange={onSelectEighthQuestion}
								hasError={errors.includes(eighthQuestion.name)}
								required={eighthQuestion.required}
								dateFormat="dd/MM/yyyy"
								minDate={
									new Date(eighthQuestion.extraParams?.min! + 'T12:00:00')
								}
							/>
						</div>

						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_temporaryUseOfPesticide_infestation_historyn
							}
						</h5>

						<TextAreaInput
							id={ninethQuestion.name}
							label={ninethQuestion.label}
							value={ninethQuestion.value}
							onChange={onSelectNinethQuestion}
							hasError={errors.includes(ninethQuestion.name)}
							maxCharacters={500}
							rows={2}
							required={ninethQuestion.required}
							ref={ninethQuestion.ref as RefObject<HTMLTextAreaElement>}
						/>

						<CheckboxInput
							name={tenthQuestion.name}
							label={tenthQuestion.label}
							value={`${tenthQuestion.value}`}
							list={controlMethodsList}
							onChange={onChangeTenthQuestion}
							required={tenthQuestion.required}
							hasError={errors.includes(tenthQuestion.name)}
							ref={tenthQuestion.ref as RefObject<HTMLInputElement>}
							description={tenthQuestion.description}
							errors={errors}
							shouldNotDivide
							hasBackgroundWrapper
							newCheckValue
							onFixError={onFixError}
						/>

						<TextAreaInput
							id={eleventhQuestion.name}
							label={eleventhQuestion.label}
							value={eleventhQuestion.value}
							onChange={onSelectEleventhQuestion}
							hasError={errors.includes(eleventhQuestion.name)}
							maxCharacters={500}
							rows={2}
							required={eleventhQuestion.required}
							ref={eleventhQuestion.ref as RefObject<HTMLTextAreaElement>}
						/>

						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_temporaryUseOfPesticide_product_used_to_control_infestation
							}
						</h5>

						<p className={pageUtils.classes.subTitle}>
							{
								pageAssets?.request_form_temporaryUseOfPesticide_certified_contractor_who_confirmed_the_infestation
							}
						</p>

						<TextInput
							id={twelfthQuestion.name}
							label={twelfthQuestion.label}
							value={twelfthQuestion.value}
							onChange={onSelectTwelfthQuestion}
							hasError={errors.includes(twelfthQuestion.name)}
							required={twelfthQuestion.required}
							ref={twelfthQuestion.ref as RefObject<HTMLInputElement>}
							autoComplete="off"
						/>

						<TextInput
							id={thirteenthQuestion.name}
							label={thirteenthQuestion.label}
							value={thirteenthQuestion.value}
							onChange={onSelectThirteenthQuestion}
							hasError={errors.includes(thirteenthQuestion.name)}
							description={thirteenthQuestion.description}
							required={thirteenthQuestion.required}
							ref={thirteenthQuestion.ref as RefObject<HTMLInputElement>}
							autoComplete="off"
						/>

						<TextInput
							id={fouteenthQuestion.name}
							label={fouteenthQuestion.label}
							value={fouteenthQuestion.value}
							onChange={onSelectFouteenthQuestion}
							hasError={errors.includes(fouteenthQuestion.name)}
							description={fouteenthQuestion.description}
							required={fouteenthQuestion.required}
							ref={fouteenthQuestion.ref as RefObject<HTMLInputElement>}
							autoComplete="off"
						/>
					</section>
				</Collapse>

				<Collapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_required_documents}
						</h4>

						<RequestFiles
							subtitle={fifteenthQuestion.label}
							name={fifteenthQuestion.name}
							required={fifteenthQuestion.required}
							files={fifteenthQuestion.files}
							hasError={errors.includes(fifteenthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileFifteenthQuestion}
							subSteps={subSteps}
							maxFiles={fifteenthQuestion.numberMaxFiles}
							description={fifteenthQuestion.description}
						>
							{/* <div
								className={joinClasses([
									pageUtils.classes.fileHelperSection,
									pageUtils.classes.fileContainer
								])}
							>
								<Text content={fifteenthQuestion.description} />
								<BlueBulletsList
									labels={fifteenthQuestionListDescription}
									text
								/>
							</div> */}
						</RequestFiles>

						<RequestFiles
							subtitle={sixteenthQuestion.label}
							name={sixteenthQuestion.name}
							required={sixteenthQuestion.required}
							files={sixteenthQuestion.files}
							hasError={errors.includes(sixteenthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileSixteenthQuestion}
							subSteps={subSteps}
							maxFiles={sixteenthQuestion.numberMaxFiles}
							description={sixteenthQuestion.description}
						></RequestFiles>
						<RequestFiles
							subtitle={seventeenthQuestion.label}
							name={seventeenthQuestion.name}
							required={seventeenthQuestion.required}
							files={seventeenthQuestion.files}
							hasError={errors.includes(seventeenthQuestion.name)}
							removeSectionMarginTop
							onSetHasError={onSetErrorFromFileComponent}
							onSetFiles={onSelectFileSeventeenthQuestion}
							subSteps={subSteps}
							maxFiles={seventeenthQuestion.numberMaxFiles}
							description={seventeenthQuestion.description}
						></RequestFiles>
					</section>
				</Collapse>
			</>
		)
	}

export default TemporaryPesticideApplicationPermitForIndividualsForm
